import React from 'react';
import { Col } from 'reactstrap';
import styles from './picCard.module.scss';

class PicCard extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef;
    this.state = {
      big: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.setState({
      big: !this.state.big
  });
  }
  
  render() {
    return (
      <Col md={this.props.md ? this.props.md : 4} className={styles.angebotCol}>
        <a href={this.props.link} target={this.props.target} rel="noopener noreferrer">
          {this.props.image ? <div className={styles.partnerImg} style={{backgroundImage: `url(${this.props.image})` }}></div> : ''}        
          
          {this.props.h1 ? <div><h3>{this.props.h1}</h3></div> : ''}          
        </a>
        <div style={{'fontSize': 'small'}}>{this.props.subText}</div>
        <div style={{'fontSize': 'small', 'fontStyle': 'italic'}}>{this.props.subText2}</div>
        <hr className={styles.angebotSpacer}/>
        <div className={this.state.big ? styles.angebotText : styles.angebotText} onClick={this.handleClick}>
          {this.props.text}
        </div>
      </Col>
    )
  }
}

export default PicCard;