import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from '../../components/layout';
import HeadLine from '../../components/headLine';
import imgFb from '../../assets/images/fb.jpeg';
import imgInsta from '../../assets/images/insta.png';
import imgTwitter from '../../assets/images/twitter.svg';
import imgTumblr from '../../assets/images/tumblr.svg';
import imgYt from '../../assets/images/yt.svg';
import imgMaps from '../../assets/images/maps.png';
import imgDiscord from '../../assets/images/discord.svg';

import styles from './contact.module.scss';


function Contact() {
  return (
    <Layout pagename="Kontakt & Anfahrt">
      <Container  className={styles.container}>
        <Row className={styles.row}>
          <Col md="12" lg="6" className={styles.colText}>
            <b>Attitude Sports NB - Martial Arts Academy </b>
            <br/>
            Sporttrainer Dennis Schulz
            <br/>
            Kuhdamm 3a
            <br/>
            17033 Neubrandenburg
            <br/>
            <br/>
            Tel.: 015170099473
            <br/>
            E-Mail: <a href="mailto:info@attitudesports.de">info@attitudesports.de</a>       
            <div>
              <a className={styles.link} title="Discord" href="https://discord.gg/THVWXhrAWD" target="_blank" rel="noopener noreferrer">
                <img className={styles.icon} src={imgDiscord} alt="Discord" />
              </a>
              <a className={styles.link} title="Facebook" href="https://www.facebook.com/AttitudeSportsNB/" target="_blank" rel="noopener noreferrer">
                <img className={styles.icon} src={imgFb} alt="Facebook" />
              </a>
              <a className={styles.link} title="Instagram" href="https://www.instagram.com/attitudesportsnb/" target="_blank" rel="noopener noreferrer">
                <img className={styles.icon} src={imgInsta} alt="Instagram" />
              </a>
              <a className={styles.link} title="Twitter" href="https://twitter.com/AttitudeNb" target="_blank" rel="noopener noreferrer">
                <img className={styles.icon} src={imgTwitter} alt="Twitter" />
              </a>
              <a className={styles.link} title="Tumblr" href="https://www.tumblr.com/blog/attitudesportsnb" target="_blank" rel="noopener noreferrer">
                <img className={styles.icon} src={imgTumblr} alt="Tumblr" />
              </a>
              <a className={styles.link} title="YouTube" href="https://www.youtube.com/channel/UCQczhCmq1oqKXnnuTX1PzsA" target="_blank" rel="noopener noreferrer">
                <img className={styles.icon} src={imgYt} alt="YouTube" />
              </a>
              <a className={styles.link} title="Google Maps" href="https://www.google.de/maps/place/Attitude+Sports+NB+-+Martial+Arts+Academy/@53.5618582,13.2701508,17z/data=!3m1!4b1!4m5!3m4!1s0x47abc3bb91686e5f:0xe2feee429604b602!8m2!3d53.561855!4d13.2723395" target="_blank" rel="noopener noreferrer">
                <img className={styles.icon} src={imgMaps} alt="Google Maps" />
              </a>
            </div>              
          </Col>
{/* 
          <Col md="12" lg="4" className={styles.colText}>
            <iframe src="https://discord.com/widget?id=1178707020879241226&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
          </Col> */}

          <Col md="12" lg="6" className={styles.colMap}>
          <iframe className={styles.map} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2369.7708381807442!2d13.270150815959239!3d53.56185816624761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47abc3bb91686e5f%3A0xe2feee429604b602!2sAttitude%20Sports%20NB%20-%20Martial%20Arts%20Academy!5e0!3m2!1sde!2sde!4v1574631652386!5m2!1sde!2sde" 
            width="100%" 
            height="500" 
            frameborder="0" 
            style={{border: "none"}}
            allowfullscreen="true"
            title="Google Map">
          </iframe>
          </Col>
        </Row>

        <Row className={styles.rowDiscord} style={{marginTop: '25px'}}>
          <Col md={'*'}>
            <a className={styles.link} title="Discord" href="https://discord.gg/THVWXhrAWD" target="_blank" rel="noopener noreferrer">
              <img className={styles.iconDiscord} src={imgDiscord} alt="Discord" />                
            </a>
          </Col>
          <Col>
            <a className={styles.link} style={{'textAlign': 'center'}} title="Discord" href="https://discord.gg/THVWXhrAWD" target="_blank" rel="noopener noreferrer">
              <div><b>Auf unserem Discord Server kannst du auch direkt mit uns und unserem Team in Kontakt treten.</b></div>
            </a>            
          </Col>

          <Col md={'*'}>
            <a className={styles.link} title="Discord" href="https://discord.gg/THVWXhrAWD" target="_blank" rel="noopener noreferrer">
              <img className={styles.iconDiscord} src={imgDiscord} alt="Discord" />                
            </a>
          </Col>
        </Row>

        <Row className={styles.rowBlank}>
          <Col md="12" className={styles.colText}>
            <h3>Indoor Ansicht</h3>
            <iframe title="Cage" src="https://www.google.com/maps/embed?pb=!4v1615106825790!6m8!1m7!1sCAoSLEFGMVFpcE5lU0tUdDg2NnZxMXRVMjZ0NmxSX1NOTTQtMEh0aG9KSGt5bEhB!2m2!1d53.5684954!2d13.2465645!3f10.09764536339977!4f-12.414854258672733!5f0.7820865974627469" width="100%" height="450" style={{"border":0}} allowfullscreen="" loading="lazy"></iframe>
          </Col>
        </Row>
      </Container>

      <HeadLine 
          text="Was die Presse über uns sagt"
      />

      {/* <Container fluid style={{color: '#ddd', marginTop: '15px'}}>
          <Row style={{backgroundColor: '#8A191C'}}>
            <Col style={{minHeight:'100px'}}>
              <a href="/preise">
                <h2 className={styles.center}>Presse</h2>
              </a>
            </Col>
          </Row>
        </Container> */}

      <Container>
        {/* <Row style={{color: '#ddd'}}>
            <Col style={{height:'100px'}}>
              <h2 className={styles.center}>Was die Presse über uns sagt</h2>
            </Col>
          </Row> */}

         <Row>
            {/* <Col md={4} className={styles.angebotCol}>
              <a href="https://www.supernovamag.de/antifa-kampfsport/" target="_blank" rel="noopener noreferrer">
                <div className={styles.presseImg} style={{backgroundImage: 'url(https://www.supernovamag.de/wp-content/uploads/2018/11/Domma_SN_NB_A_SocialMedia_035.jpg)' }}></div>
              </a>
              <div><h3>Supernova</h3></div>
              <hr className={styles.angebotSpacer}/>
              <div>
                <h3>Auf der Matte mit Antifas</h3>
                Dass Linke Kampfsport machen, ist nicht unumstritten. Gerade MMA, wo praktisch ohne Regeln gekämpft wird und fast alles erlaubt ist, sehen viele in der Szene kritisch. Man würde die Methoden der Nazis kopieren, heißt es oft. Das sieht Sunny nicht so. Man könne auch Kampfsport machen und friedlich sein. „Ich sage immer: Lieber ein Krieger im Garten als eine Gärtner im Krieg.“ Außerdem habe der „Nazi-Box-Kult“ nicht viel mit den ursprünglichen Ideen des Kampfsports zu tun. Dort geht es nämlich um Respekt vor dem Gegner und gerade auch um die Unterstützung von schwächeren Kämpfer*innen. Bewusst grenzt sich „Attitude“ von anderen Klubs ab. Bei denen geht es fast ausschließlich um Härte und Männlichkeit.
                <br/>
                <a href="https://www.supernovamag.de/antifa-kampfsport/" target="_blank" rel="noopener noreferrer"><b style={{fontSize: '24pt'}}>...</b></a>
              </div>
            </Col> */}
            <Col md={4} className={styles.angebotCol}>
              <a href="https://www.nd-aktuell.de/artikel/1107534.mixed-matrial-arts-auf-der-matte-mit-antifas.html?sstr=Auf|der|Matte|mit|Antifas" target="_blank" rel="noopener noreferrer">
                <div className={styles.presseImg} style={{backgroundImage: 'url(https://www.nd-aktuell.de/images/nd-website-logo-regular.svg)' }}></div>
              </a>
              <div><h3>neues deutschland</h3></div>
              <hr className={styles.angebotSpacer}/>
              <div>
                <h3>Auf der Matte mit Antifas</h3>
                Dass Linke Kampfsport machen, ist nicht unumstritten. Gerade MMA, wo praktisch ohne Regeln gekämpft wird und fast alles erlaubt ist, sehen viele in der Szene kritisch. Man würde die Methoden der Nazis kopieren, heißt es oft. Das sieht Sunny nicht so. Man könne auch Kampfsport machen und friedlich sein. „Ich sage immer: Lieber ein Krieger im Garten als eine Gärtner im Krieg.“ Außerdem habe der „Nazi-Box-Kult“ nicht viel mit den ursprünglichen Ideen des Kampfsports zu tun. Dort geht es nämlich um Respekt vor dem Gegner und gerade auch um die Unterstützung von schwächeren Kämpfer*innen. Bewusst grenzt sich „Attitude“ von anderen Klubs ab. Bei denen geht es fast ausschließlich um Härte und Männlichkeit.
                <br/>
                <a href="https://www.nd-aktuell.de/artikel/1107534.mixed-matrial-arts-auf-der-matte-mit-antifas.html?sstr=Auf|der|Matte|mit|Antifas" target="_blank" rel="noopener noreferrer"><b style={{fontSize: '24pt'}}>...</b></a>
              </div>
            </Col>

            <Col md={4} className={styles.angebotCol}>
              <a href="https://www.anarchistischefoderation.de/metadiskursiv-linke-kampf-sportvereine-in-deutschland/" target="_blank" rel="noopener noreferrer">
                <div className={styles.presseImg} style={{backgroundImage: 'url(https://www.anarchistischefoderation.de/xheader_anarchistischefoderation.de.png,qx26585.pagespeed.ic.QmeebO5ZLJ.webp)' }}></div>
              </a>
              <div><h3>METADISKURSIV</h3></div>
              <hr className={styles.angebotSpacer}/>
              <div>
                <h3>Linke (Kampf-)Sportvereine in Deutschland</h3>
                <b>Sportverein? – Selbst aktiv werden!</b>
                <br/>
                Das Gym von Attitude Sports wurde als diy Konzept gegründet. Das bedeutet, dass die Arbeit ehrenamtlich erfolgt und jeglicher Gewinn wieder in Equipment und Seminare gesteckt wird. Grundlage war eine Gruppe, welche Bock auf Kampfsport und keine Angst hatte etwas zu riskieren, um sich den Traum vom eigenen Trainingscenter zu erfüllen. Als wichtigster Tipp, der zum Nachmachen anregen soll, haben sie uns daher „machen, machen, machen“ mit auf den Weg gegeben!
                <br/>
                <a href="https://www.anarchistischefoderation.de/metadiskursiv-linke-kampf-sportvereine-in-deutschland/" target="_blank" rel="noopener noreferrer"><b style={{fontSize: '24pt'}}>...</b></a>
              </div>
            </Col>

            <Col md={4} className={styles.angebotCol}>
              <a href="https://veto-mag.de/kampfsport/" target="_blank" rel="noopener noreferrer">
                <div className={styles.presseImg} style={{backgroundSize: 'contain', backgroundColor: '#aaa', backgroundImage: 'url(https://veto-mag.de/wp-content/uploads/2023/12/Veto_Logo_new-e1702385681484.webp)' }}></div>
              </a>
              <div><h3>Veto</h3></div>
              <hr className={styles.angebotSpacer}/>
              <div>
                <h3>Linker Haken — Attitude Sports</h3>
                Ein lauter, dumpfer Schlag hallt durch den Raum. Mit Wucht trifft der blanke Spann auf die lederne Hülle des kiloschweren Boxsacks. Das Trainingsgerät schwingt ungebremst in alle Richtungen, bis Sunny es wieder stoppt. Der großgewachsene Kämpfer zerrt am Bund seiner Baumwolljacke, um alles zu richten. Auf dem Rücken seines Oberteils, das traditionell beim Judo getragen wird, hat er einen Stoffaufnäher der US-Punkband Dead Kennedys angebracht, vorne prangt der Spruch „Good Night White Pride“. Hier ist einiges anders als gewöhnlich – und das ganz bewusst.
                <br/>
                <a href="https://veto-mag.de/kampfsport/" target="_blank" rel="noopener noreferrer"><b style={{fontSize: '24pt'}}>...</b></a>
              </div>
            </Col>
          </Row>

          <Row className={styles.rowBlank}>
            <Col md="12" className={styles.col1} style={{textAlign: 'center'}}>
              <h3>Interview mit der AJUCA Crew</h3>
              <iframe title="Ajuca Interview" width="100%" height="500" src="https://www.youtube.com/embed/R6STqpH9iFc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Col>
          </Row>
      </Container>
    </Layout>    
  );
}

export default Contact;