import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import Layout from '../../components/layout';
import YouTube from 'react-youtube';
import styles from './home.module.scss';
import data from '../../assets/data.js';
import partnerData from '../../assets/partner.js';
import things from '../../assets/8things.js';
import { AiFillStar } from 'react-icons/ai';
import PicCard from '../../components/picCard';
import HeadLine from '../../components/headLine';
import Partner from '../partner'

import paypalme from "../../assets/images/paypalme.png"
import imgYt from '../../assets/images/yt.svg';

import imgFb from '../../assets/images/fb.jpeg';
import imgInsta from '../../assets/images/insta.png';
import imgTwitter from '../../assets/images/twitter.svg';
import imgTumblr from '../../assets/images/tumblr.svg';
import imgMaps from '../../assets/images/maps.png';
import imgDiscord from '../../assets/images/discord.svg';
import imgNo from '../../assets/images/Attitude Sports NB500.png';

class Home extends React.Component {
  constructor(props) {
    super(props)

    this.opts = {
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        controls: 0, 
        rel : 0,
        fs : 0,
        loop: 1,
        showinfo: 0,
        mute: 1
      }
    }; 

    this.youTubeContainer = React.createRef();

    this.angebote = [];
    data.martialArts.forEach((martialArt, i) => {
      if (martialArt.hideAsCourese) return;
      this.angebote.push(
        <PicCard 
          link={`/sports#${martialArt.anker}`}
          image={require(`../../assets/images/${martialArt.image}`)}
          h1={martialArt.name}
          subText={martialArt.shortDescription}
          text={martialArt.description}
          subText2={martialArt.age}
        />
      )
    });

    this.partnerRows = [];
    partnerData.partner.forEach(p => {
      this.partnerRows.push(
          <PicCard 
          link={p.link}
          image={p.image}
          h1={p.h1}
          subText={p.h2}
          text={p.text}
          target="_blank"
        />
      );
    });

    this.thingsRows = [];
    things.things.forEach(p => {
      this.thingsRows.push(
          <PicCard 
          md='3'
          image={p.image}
          text={p.text}
        />
      );
    });
  }

  _onEnd(event) {
    event.target.playVideo();
  }
    
  render () {
    const stars = <span><AiFillStar className={styles.star} /><AiFillStar className={styles.star} /><AiFillStar className={styles.star} /><AiFillStar className={styles.star} /><AiFillStar className={styles.star} /></span>

    return (
      <Layout>

        <Container fluid style={{color: '#ddd', padding: 0, textAlign: 'center', height: '75vh', overflow: 'hidden'}}>
          <div className={styles.centered1}>
            <img src={imgNo} alt='Damit dein NEIN, mehr beinhaltet als Hoffnung!' style={{maxHeight: '150px', position: 'absolute', bottom: '5px', right: '5px'}} />
            <div className={styles.centered2}>
            
            </div>
            <div className={styles.centered3}>
              <div>              
                <span>Attitude Sports NB - Martial Arts Academy | ZR Team Germany</span>
                <h1>Kampfkunst, die Funktioniert!</h1>
                <p><b>Damit dein NEIN, mehr beinhaltet als Hoffnung! </b></p>
                <Button href={`https://attitudesports.gymdesk.com/signup/v/DLO0v?membership=13640&type=1`} target="_blank" rel="noopener noreferrer" className={styles.btn}>JETZT PROBETRAINING VEREINBAREN!</Button>
              </div>                              
            </div>
            
          </div>
        </Container>

        <Container className={styles.cardContainer}>
          <Row>
            <Col className={styles.angebotCol}>
            
              <div className={styles.firstH}>              
                <h2 invert='true'>Kampfkunst, die funktioniert!</h2>
              </div>
              <hr className={styles.angebotSpacer}/>
              <div>
                Wir sind mehr als nur ein Kampfsport-Gym – wir sind eine Gemeinschaft, die dir zeigt, wie du durch regelmäßiges Training nicht nur Kampftechniken meisterst, sondern auch Selbstvertrauen und Sicherheit gewinnst. Mit unserem Training wird dein Nein künftig mehr beinhalten, als nur Hoffnung.
                <br/><br/>
                Egal, ob du deine Fähigkeiten verbessern oder dich auf Wettkämpfe vorbereiten möchtest – bei uns ist jede*r willkommen, unabhängig von Herkunft, Alter oder Geschlecht. Wir schaffen eine Umgebung, in der sich alle sicher und respektiert fühlen, frei von Diskriminierung.
                <br/><br/>
                Unsere erfahrenen Instruktor*innen bieten dir eine umfassende Trainingsumgebung, in der wir gemeinsam an deinen Zielen arbeiten. Mit regelmäßigem Training und Hingabe kannst du alles erreichen. Werde Teil unserer Gemeinschaft und erlebe, wie du deine Ziele in weniger als einem Jahr erreichst!
              </div>
              
            </Col>  
          </Row>
          <Row>
            <Col>
            {/* <script src="https://gymdesk.com/js/widgets.js"></script>
            <div class="gymdesk-booking" attr-gym="6Vr3Y" attr-schedule="all"></div>
            <div class="maonrails-schedule" attr-gym="6Vr3Y" attr-schedule="all" attr-program="all"></div> */}
            </Col>
          </Row>
        </Container>

        <Container style={{color: '#ddd'}}>        
          <Row>
            <YouTube
              videoId='c4Ro1RFkzjc'//'AuNddioj6Tk'                // defaults -> null
              id='video'                      // defaults -> null
              className={styles.youtube}                // defaults -> null
              containerClassName={['youtubeContainer', styles.youtubeContainer, styles.shadow].join(' ')}       // defaults -> ''
              opts={this.opts}                        // defaults -> {}
              onEnd={this._onEnd}
            />
          </Row>
          <Row>
            <Col style={{'textAlign': 'center', 'color': 'black'}}>           
              <div className={styles.ytlink} >
                Mehr Videos in unserem YouTube Kanal!
              </div>
              <div className={styles.ytlink} >
                <a title="YouTube" href="https://www.youtube.com/channel/UCQczhCmq1oqKXnnuTX1PzsA" target="_blank" rel="noopener noreferrer">
                  
                  <img className={styles.icon} src={imgYt} alt="YouTube" />                  
                </a>
              </div>
              <div className={styles.ytlink} >
                Oder folge uns in einem unser Kanäle!
              </div>
              <div className={styles.ytlink} >                
                <a className={styles.ytlink} title="Discord" href="https://discord.gg/THVWXhrAWD" target="_blank" rel="noopener noreferrer">                  
                  <img className={styles.icon} src={imgDiscord} alt="Discord" />
                </a>
                <a className={styles.ytlink} title="Facebook" href="https://www.facebook.com/AttitudeSportsNB/" target="_blank" rel="noopener noreferrer">
                  <img className={styles.icon} src={imgFb} alt="Facebook" />
                </a>
                <a className={styles.ytlink} title="Instagram" href="https://www.instagram.com/attitudesportsnb/" target="_blank" rel="noopener noreferrer">
                  <img className={styles.icon} src={imgInsta} alt="Instagram" />
                </a>
                <a className={styles.ytlink} title="Twitter" href="https://twitter.com/AttitudeNb" target="_blank" rel="noopener noreferrer">
                  <img className={styles.icon} src={imgTwitter} alt="Twitter" />
                </a>
                <a className={styles.ytlink} title="Tumblr" href="https://www.tumblr.com/blog/attitudesportsnb" target="_blank" rel="noopener noreferrer">
                  <img className={styles.icon} src={imgTumblr} alt="Tumblr" />
                </a>            
                <a className={styles.ytlink} title="Google Maps" href="https://www.google.de/maps/place/Attitude+Sports+NB+-+Martial+Arts+Academy/@53.5618582,13.2701508,17z/data=!3m1!4b1!4m5!3m4!1s0x47abc3bb91686e5f:0xe2feee429604b602!8m2!3d53.561855!4d13.2723395" target="_blank" rel="noopener noreferrer">
                  <img className={styles.icon} src={imgMaps} alt="Google Maps" />
                </a>
              </div>              
            </Col>
          </Row>
        </Container>

        <HeadLine 
          text="8 Gründe, warum du bei uns Kampfsport trainieren solltest"
        />

        <Container className={styles.cardContainer}>
          <Row style={{marginTop: '25px'}}>
            {this.thingsRows}
          </Row>
        </Container>

        <HeadLine 
          text={'Schon überzeugt?'}
        />

        <Container className={styles.cardContainer}>
          <Row>
            <Col className={styles.angebotCol}>
              <div className={styles.firstH}>
                <Button href={`https://attitudesports.gymdesk.com/signup/v/DLO0v?membership=13640&type=1`} target="_blank" rel="noopener noreferrer" className={styles.btn}>Jetzt kostenloses Probetraining vereinbaren!</Button>
              </div>
            </Col>
          </Row>
        </Container>  

        <HeadLine 
          text="Unser Angebot"
        />

        <Container className={styles.cardContainer}>
          <Row className=''>
            {this.angebote}
          </Row>
        </Container>

        <HeadLine 
          text="Was die Leute über uns sagen"
        />

        <Container className={styles.cardContainer}>
          <Row style={{marginTop: '25px'}}>
            <PicCard 
              h1='yogawithcarl'
              subText={stars}
              text='Top, club entspannte trainingsatmosphare und Freunde auf der matte und der Straße'              
            />
            <PicCard 
              h1='Ragnaroek'
              subText={stars}
              text='Trainingsstätte,  Ausrüstung und Personal sind top. Es wird einem alles geboten, was man sich von einem Kampfsportverein wünscht.'
            />
            <PicCard 
              h1='Arne'
              subText={stars}
              text='Sympathisch und sehr kompetent. Nur zu empfehlen.'
            />
            <PicCard 
              h1='Simon B'
              subText={stars}
              text='Was ein Hammer Gym...Das Training ist sehr gut gestaltet und der Trainer ist super nett und aufmerksam.
              Es hat Spaß gemacht mit den Leuten dort zu trainieren.
              Das Gym hat Flair und ich habe mich als Neuer sofort wohlgefühlt.
              Im Sommer soll es sogar einen Cage unter freiem Himmel geben.
              100% Weiterempfehlung!'
            />
            <PicCard 
              h1='Wir Du'
              subText={stars}
              text='Geilster Verein in dem ich je trainiert habe..!'
            />
            <PicCard 
              h1='Даниил Рябченко'
              subText={stars}
              text='(Übersetzt von Google) Sehr cooler Wrestling Club, ich gehe eine Woche und es gefällt mir sehr.

              (Original)
              Очень крутой клуб борьбы, я хожу одну неделю и мне очень нравится.'
            />
          </Row>
        </Container>

        <HeadLine 
          text={'Jetzt überzeugt?'}
        />

        <Container className={styles.cardContainer}>
          <Row>
            <Col className={styles.angebotCol}>
              <div className={styles.firstH}>
                <Button href={`https://attitudesports.gymdesk.com/signup/v/DLO0v?membership=13640&type=1`} target="_blank" rel="noopener noreferrer" className={styles.btn}>Jetzt kostenloses Probetraining vereinbaren!</Button>
              </div>
            </Col>
          </Row>
        </Container>   

        <HeadLine 
          text="Unsere Partner"
        />

        <Container className={styles.cardContainer}>        
          <Row>
            <Partner md='3' asCard={true}/>
          </Row>
        </Container>

        <HeadLine 
          text="Attitude Sports braucht deine Hilfe!"
        />

        <Container className={styles.cardContainer}>
          <Row>
            <Col className={styles.angebotCol}>
              <a href="https://paypal.me/attitudesports?country.x=DE&locale.x=de_DE" target="_blank" rel="noopener noreferrer">
                <div className={styles.partnerImg} style={{backgroundImage: `url(${paypalme})` }}></div>
              </a>
              <div>
                <h3>Etwas Gutes tun</h3>
                <h4>Unterstütze uns über Paypal</h4>
              </div>
              <hr className={styles.angebotSpacer}/>
              <div className={styles.angebotText}>
                Da wir nicht nur ein professionelles Kampfsport-Gym sind, sondern uns auch als soziales Projekt verstehen, sind wir hin und wieder auf Spenden angewiesen, um unsere Kurspreise so gering wie möglich halten zu können und allen Menschen das Training zu ermöglichen.
                <br/>
                Wenn du also etwas Gutes tun möchtest, aber nicht unbedingt Kampfsport machen willst, kannst du uns gern auch über Paypal unterstützen.
              </div>
            </Col>  
          </Row>
        </Container>

        <HeadLine 
          text={<div>Sicherheit & Erfolg nur einen Klick entfernt! </div>}
        />

        <Container className={styles.cardContainer}>
          <Row>
            <Col className={styles.angebotCol}>
              <div className={styles.firstH}>
                <Button href={`https://attitudesports.gymdesk.com/signup/v/DLO0v?membership=13640&type=1`} target="_blank" rel="noopener noreferrer" className={styles.btn}>Jetzt kostenloses Probetraining vereinbaren!</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>    
    );
  }
}
  
export default Home;