import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Router from './components/router';
import * as serviceWorker from './serviceWorker';

import themes from 'devextreme/ui/themes';

import './assets/css/dx.generic.custom-scheme.css';

themes.initialized(() => ReactDOM.render(
    <Router />,
    document.getElementById('root')
  ));

// ReactDOM.render(<Router />, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
