import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from '../../components/layout';
import PagePath from '../../components/layout/header/pagepath';
import style from './sports.module.scss';
import data from '../../assets/data.js';
import { toTimeString } from '../../converters/basic';

class Sports extends React.Component {
  getTrainers = (martialArt) => {
    let trainers =  data.trainer.filter(_trainer => _trainer.trainerFor.includes(martialArt.anker));
    let trainersDiv = [];

    if (trainers.length > 0) {trainersDiv.push(<span key={'trainer'}>Trainer: </span>) }
    trainers.forEach((trainer, i) => {
      if (trainer.hideme) { return }
      let komma = i < trainers.length - 1 ? ', ' : '';
      trainersDiv.push(<a key={trainer.anker} href={"trainer#" + trainer.anker}> { trainer.name + komma } </a>)
    });
    if (trainers.length > 0) {trainersDiv.push(<br key={'br'} />) }

    return <b> {trainersDiv} </b>;
  }

  getTimes = (martialArt) => {
    let times = [];
    martialArt.time ?
    martialArt.time.forEach((time, i) => {
      times.push(<div key={i} style={{marginTop: '5px', width: '50%', display: 'inline-block'}}> 
        { data.days.filter(day => day.id === time.day)[0].name }:
        <br/>
        { toTimeString(time.start) } - { toTimeString(time.end) }
      </div>);
    })
    : times.push("Coming soon");

    return <div> { times } </div>;
  }

  createRows = () => {
    let rows = [];
    data.martialArts.forEach((martialArt, i) => {
      if (martialArt.hideAsCourese) { return }

      rows.push(        
        <Col md={6} key={i} id={martialArt.anker}>          
          <img src={require(`../../assets/images/${martialArt.image}`)}
              alt={martialArt.image}
              className={style.img}
          />
          
          {martialArt.name ? <div><h3>{martialArt.name} </h3></div> : ''}
         
          <div><i>{martialArt.shortDescription}</i></div>
          { martialArt.age ? <span style={{fontSize: '0.8em'}}>{martialArt.age}<br/></span> : '' }
          <div style={{'fontSize': 'small'}}>{this.props.subText}</div>
          <div>{this.getTimes(martialArt)}</div>

          <hr className={style.angebotSpacer}/>

          <div>
            {this.getTrainers(martialArt)}            
            { martialArt.description }
          </div>
        </Col>
      )
    });
    return rows;
  }

  render() {
    return (
      <Layout pagename="4 Fghtr Prinzip">
        <Container>
          <Row className={style.row}>
            <Col>
              Das 4Fghtr Prinzip (For Fighter). Unsere mit 4Fghtr markierten Kurse folgen einem klaren Prinzip: Sie sind darauf ausgerichtet, dir <b>praktische Erfahrung</b> für den Ernstfall zu vermitteln und deine Fähigkeiten für Wettkämpfe zu schärfen.
              <br/><br/>
              Bei uns steht Erfahrung an erster Stelle. Wir legen großen Wert darauf, dass das im Sparring Gelernte deine Fähigkeiten für die <b>Realität</b> schärft. Die <b>Anwendbarkeit</b> in echten Situationen ist entscheidend, daher widmen wir viel Zeit und Aufmerksamkeit dem praktischen Training.
              <br/><br/>
              Techniktraining ist fundamental und steht an zweiter Stelle. Es bildet die Basis für deine Fähigkeiten im Sparring. Wir legen Wert darauf, dass jede Technik, die gelehrt wird, auch <b>in der Praxis funktioniert</b> und anwendbar ist.
              <br/><br/>
              Fitness hat bei uns die unterste Priorität. <b>Wir integrieren</b> Fitnessübungen in das Training, die direkt auf das Techniktraining abzielen. Dabei vermeiden wir separate Fitnessübungen, da jede Minute im Training darauf ausgerichtet ist, deine Kampfsportfähigkeiten zu verbessern. Zeit für isolierte Fitnessübungen bleibt aus, da wir sicherstellen möchten, dass du das Beste aus deinem Kampfsporttraining herausholst.
              <br/><br/>
              Bei uns zahlst du für ein Training, das auf praktischer Erfahrung basiert, dir fundierte Techniken vermittelt und deine Fitness verbessert – alles mit dem klaren Ziel, dich <b>für den Ernstfall</b> und Wettkämpfe vorzubereiten.
              
            </Col>
          </Row>         
        </Container>

        <Container fluid>
          <Row className={style.row}>
            <Col md="12" style={{padding:0}}>
              <PagePath pagename="Sportarten" />
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className={style.row}>
            { this.createRows() }
          </Row>         
        </Container>
      </Layout>      
    );
  }    
}

export default Sports;