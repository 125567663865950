import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from '../../components/layout';

import style from './probetraining.module.scss';
import imgDiscord from '../../assets/images/discord.svg';


function Probetraining() {
  return (
    <Layout pagename="Probetraining">
      <Container  className={style.container}>
        <Row className={style.row}>
          <Col className={style.col}>
            <p>Wir freuen uns, dass du Interesse an einem Probetraining bei uns hast. Bitte fülle das unten stehende Formular aus, um dich anzumelden. Das erste Training ist für dich kostenlos, damit du einen ersten Eindruck von unserem Angebot gewinnen kannst. Wenn du dann noch unentschlossen bist, kannst du gern noch einen Probemonat buchen, um unser Training im vollen umfang kennenlernen zu können.</p>
            <p><strong><a href="https://attitudesports.maonrails.com/signup?membership=13640&type=1" target="_blank" rel="noopener noreferrer">Registrierungsformular für das Probetraining öffnen</a></strong></p>            
           
            <p><em>Hinweis: Die von dir angegebenen Informationen werden vertraulich behandelt und ausschließlich zur Kontaktaufnahme und Organisation des Probetrainings verwendet.</em></p>
            <p>Nachdem du das Formular abgeschickt hast, bist du herzlich eingeladen, zu einer beliebigen Trainingseinheit zu kommen, ohne dich vorher anmelden zu müssen. Unser <a href="/events" >Trainingsplan</a> ist auf unserer Homepage verfügbar, sodass du die für dich passende Trainingseinheit auswählen kannst.</p>
            {/* <p>Unsere professionellen Trainer stehen dir während des Probetrainings zur Seite, um sicherzustellen, dass du das Beste aus deiner Trainingseinheit herausholst. Sie werden dir bei der Auswahl der Übungen behilflich sein und dir wertvolle Tipps für dein individuelles Training geben.</p> */}
            <p>Nutze diese Gelegenheit, um unsere Einrichtungen kennenzulernen, unser Trainingskonzept zu erleben und dich von der Qualität unseres Angebots zu überzeugen. Wir sind überzeugt, dass du von den Vorteilen unseres Trainings profitieren wirst.</p>
            {/* <p>Bitte beachte, dass die Verfügbarkeit in den Trainingseinheiten begrenzt sein kann. Wir empfehlen dir daher, frühzeitig zu erscheinen, um deinen Platz zu sichern. Bei Fragen oder für weitere Informationen stehen dir unsere Mitarbeiter vor Ort gerne zur Verfügung.</p> */}
            <p>Wir freuen uns darauf, dich beim Training zu unterstützen und dir dabei zu helfen, deine Ziele zu erreichen. Bis bald!</p>
            {/* <p><em>Hinweis: Das Angebot für die ersten drei kostenlosen Trainingseinheiten gilt nur für neue Kunden und kann nicht mit anderen Angeboten oder Rabatten kombiniert werden.</em></p> */}
            <a title="Discord" href="https://discord.gg/THVWXhrAWD" target="_blank" rel="noopener noreferrer">
              <div>
              <img className={style.icon} src={imgDiscord} alt="Discord" />
                <b>Du hast weitere Fragen?</b> <br/>
                Auf unserem Discord Server beantworten wir dir gern all deine Fragen und du kannst bei Bedarf mit unserem gesamten Team in Kontakt treten und in Kontakt bleiben.
              </div>
            </a>
          </Col>
        </Row>
      </Container>
    </Layout>    
  );
}

export default Probetraining;