import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from '../../components/layout';

import imgMaf1 from '../../assets/images/Martial Arts Festival 2024/1.svg';
import imgMaf2 from '../../assets/images/Martial Arts Festival 2024/2.svg';
import imgMaf3 from '../../assets/images/Martial Arts Festival 2024/3.svg';
import imgMaf4 from '../../assets/images/Martial Arts Festival 2024/4.svg';
import imgMaf5 from '../../assets/images/Martial Arts Festival 2024/5.svg';
import imgMaf6 from '../../assets/images/Martial Arts Festival 2024/6.svg';
import imgMaf7 from '../../assets/images/Martial Arts Festival 2024/7.svg';
import imgMaf8 from '../../assets/images/Martial Arts Festival 2024/8.svg';
import imgMaf9 from '../../assets/images/Martial Arts Festival 2024/9.svg';
import imgMaf10 from '../../assets/images/Martial Arts Festival 2024/10.svg';
import imgMaf11 from '../../assets/images/Martial Arts Festival 2024/11.svg';
import imgMaf12 from '../../assets/images/Martial Arts Festival 2024/12.svg';
import imgMaf13 from '../../assets/images/Martial Arts Festival 2024/13.svg';

import style from './maf.module.scss';


function Impressum() {
  return (
    <Layout pagename="Martial Arts Festival 2024">
      <Container  className={style.container}>
        <Row className={style.row}>          
          <Col className={style.col}>            
            <div class="container">
              <p><strong>Event:</strong> Martial Arts Festival 2024</p>
              <p><strong>Datum:</strong> 14.06.2024 15:00 Uhr - 16.06.2024 16:00 Uhr</p>
              <p><strong>Ort:</strong> Kuhdamm 3a, 17033 Neubrandenburg</p>
              <p><strong>Anmeldung:</strong> Per E-Mail an <a href="mailto:maf@attitudesports.de">maf@attitudesports.de</a> mit Name, Kampfkunst, Gym/Dojo/Verein und Anwesenheitstage</p>
              <p><strong>Kosten:</strong> 25€ - 100€, je nach Selbsteinschätzung</p>

              <h2>Ablauf:</h2>
              <h3>Beginn</h3>
              <ul>
                  <li><strong>Einlass:</strong> 14.06.2024, 15:00 Uhr</li>
                  <li>Entspanntes Ankommen</li>
              </ul>
              <h3>Konzept</h3>
              <ul>
                  <li>Wir sind ein Festival, KEIN strukturiertes Seminar</li>
                  <li>Der freie Austausch steht im Vordergrund</li>
                  <li>Lehren und Lernen - ihr könnt Workshops anbieten oder nur welche besuchen</li>
                  <li>Viele kleine Workshops, statt wenige große</li>
                  <li>Ihr habt die freie Wahl, was ihr macht. Workshops, Pause, Sparring, Austauschen oder an der Bar sitzen</li>
              </ul>

              <h3>Workshops und Training:</h3>
              <ul>
                  <li>Workshops dauern zwischen 30 und 45 Minuten mit 30 Minuten Pause dazwischen</li>
                  <li>Themen: MMA, BJJ, Muay Thai, Judo, Karate, Kung Fu und vieles mehr mit Bezug zu Wettkampf und Selbstverteidigung</li>
                  <li>Geleitet von den Teilnehmern selbst, jeder darf Workshops anbieten oder nach speziellen Themen fragen (niemand muss).</li>
                  <li>Viele Meister und erfahrene Wettkämpfer sind vor Ort</li>
              </ul>

              <h3>Freies Sparring und Austausch:</h3>
              <p>Zwischendurch freie Zeit zum Austausch und Sparring auf zwei bis drei Kampfflächen, darunter zwei MMA-Käfige</p>

              <h3>Übernachtung:</h3>
              <p>Übernachtung im eigenen Zelt oder im Schlafsack auf den Matten</p>

              <h3>Erholung und Entspannung:</h3>
              <ul>
                  <li>Bar mit günstigen Getränken</li>
                  <li>Kleiner Aufstellpool zur Entspannung</li>
              </ul>

              <h3>Abschluss</h3>
              <p><strong>Abbau und Verabschiedung:</strong> 16.06.2024, 16:00 Uhr</p>

              <h2>Wichtige Infos für Teilnehmer*innen:</h2>
              <ul>
                  <li><strong>Anmeldung:</strong> Unbedingt per E-Mail (<a href="mailto:maf@attitudesports.de">maf@attitudesports.de</a>) anmelden mit vollständigen Angaben.</li>
                  <li><strong>Übernachtung:</strong> Übernachtung im eigenen Zelt oder im Schlafsack auf den Matten</li>
                  <li><strong>Ausrüstung:</strong> Eigene Trainingsausrüstung und Schutzausrüstung mitbringen, kann aber auch vor Ort gekauft werden.</li>
                  <li><strong>Verpflegung:</strong> Getränke gibt es günstig an der Bar, sonstige Verpflegung bitte selbst mitbringen.</li>
                  <li><strong>Kontakt:</strong> Weitere Informationen auf <a href="http://www.attitudesports.de/maf">www.attitudesports.de/maf</a> oder per E-Mail an <a href="mailto:maf@attitudesports.de">maf@attitudesports.de</a>.</li>
              </ul>
            </div>
          </Col>          
        </Row>
        <Row className={style.row}>
          <Col md={12} className={style.col} style={{textAlign: 'center'}}>
            <iframe width="315" height="560" src="https://www.youtube.com/embed/a8aMV4wbHTE?si=y6WiiNz8qLqvmpiR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </Col>
        </Row>
        <Row className={style.row}>
          <Col md={4} className={style.col}>
            <img className={style.imgMaf} src={imgMaf1} alt=""/>
          </Col>
          <Col md={4} className={style.col}>
            <img className={style.imgMaf} src={imgMaf2} alt=""/>
          </Col>
          <Col md={4} className={style.col}>
            <img className={style.imgMaf} src={imgMaf3} alt=""/>
          </Col>
          <Col md={4} className={style.col}>
            <img className={style.imgMaf} src={imgMaf4} alt=""/>
          </Col>
          <Col md={4} className={style.col}>
            <img className={style.imgMaf} src={imgMaf5} alt=""/>
          </Col>
          <Col md={4} className={style.col}>
            <img className={style.imgMaf} src={imgMaf6} alt=""/>
          </Col>
          <Col md={4} className={style.col}>
            <img className={style.imgMaf} src={imgMaf7} alt=""/>
          </Col>
          <Col md={4} className={style.col}>
            <img className={style.imgMaf} src={imgMaf8} alt=""/>
          </Col>
          <Col md={4} className={style.col}>
            <img className={style.imgMaf} src={imgMaf9} alt=""/>
          </Col>
          <Col md={4} className={style.col}>
            <img className={style.imgMaf} src={imgMaf10} alt=""/>
          </Col>
          <Col md={4} className={style.col}>
            <img className={style.imgMaf} src={imgMaf11} alt=""/>
          </Col>
          <Col md={4} className={style.col}>
            <img className={style.imgMaf} src={imgMaf12} alt=""/>
          </Col>
          <Col md={4} className={style.col}>
            <img className={style.imgMaf} src={imgMaf13} alt=""/>
          </Col>
        </Row>
      </Container>
    </Layout>    
  );
}

export default Impressum;