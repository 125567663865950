import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from '../../components/layout';
import PagePath from '../../components/layout/header/pagepath'

import style from './projekt.module.scss';


function Projekt() {
  return (
    <Layout pagename="Verwirkliche dein Projekt">
      <Container  className={style.container}>
        <Row className={style.row}>
          <Col className={style.col}>
            <p>Bei uns dreht sich alles um Kampfsport und die Verwirklichung eigener (Kampf)Sportprojekte. Wir bieten dir die einzigartige Möglichkeit, unsere Einrichtungen für deine eigenen Aktivitäten zu mieten oder Teil unseres Teams zu werden und dein eigenes Projekt bei uns umzusetzen.</p>
            <p>Unsere Kampfsportschule verfügt über eine großzügige Fläche von über 100 Quadratmetern Mattenfläche, um deinen Sport auszuüben. Zudem haben wir zwei hochwertige MMA-Käfige, Boxsäcke und Pratzen, um dein Training auf das nächste Level zu bringen. Für ein abwechslungsreiches Workout steht dir außerdem ein voll ausgestatteter Fitnessraum zur Verfügung, der mit verschiedenstem Equipment ausgestattet ist.</p>
            <p>Unsere Kampfsportschule verfügt über 2 Umkleideräumen mit Duschen und mehreren WC Anlagen. Außerdem hast du auch die Möglichkeit, unsere Outdoor-Anlage zu nutzen, um dein Training unter freiem Himmel zu absolvieren und deine Grenzen zu testen.</p>
            <p>Wir bieten dir nicht nur erstklassige Trainingsmöglichkeiten, sondern auch eine eigene Bar, in der du dich nach dem Training mit anderen Kampfsportbegeisterten austauschen und neue Kontakte knüpfen kannst. Hier kannst du deinen Durst stillen und die Gemeinschaft in unserer Kampfsportschule genießen.</p>
            <p>Egal, ob du ein erfahrener Kampfsportler bist, der nach einem Ort sucht, um sein Training auf die nächste Stufe zu heben, oder ein ambitionierter Einsteiger, der seine Fähigkeiten entwickeln möchte - bei uns bist du genau richtig! Wir heißen sowohl Einzelpersonen als auch Gruppen herzlich willkommen.</p>
            <p>Darüber hinaus bieten wir dir die einzigartige Möglichkeit, deine eigenen (Kampf)Sportprojekte bei uns umzusetzen. Seien es Workshops, Trainingscamps, Veranstaltungen oder regelmäßige Kursangebote - wir unterstützen dich dabei, deine Ideen zu verwirklichen und bieten dir die ideale Plattform dafür.</p>
            <p>Kontaktiere uns noch heute, um weitere Informationen zu erhalten und deine Möglichkeiten zu besprechen. Werde Teil unserer Kampfsportschule und entdecke die unendlichen Möglichkeiten, die wir dir bieten können. Zusammen schaffen wir Großartiges!</p>
            <p>Hinweis: Für weitere Details und Preise zur Vermietung unserer Einrichtungen oder zur Teilnahme an unserem Team mit deinem eigenen Projekt, bitten wir dich, uns persönlich zu kontaktieren.</p>
          </Col>
        </Row>
      </Container>

      <Container fluid>
          <Row className={style.row}>
            <Col md="12" style={{padding:0}}>
              <PagePath pagename="Firmen & Soziale Projekte" />
            </Col>
          </Row>
        </Container>

      <Container  className={style.container}>
        <Row className={style.row}>
          <Col className={style.col}>
            <p>Bei uns haben nicht nur Einzelpersonen und Gruppen die Möglichkeit, ihre eigenen (Kampf)Sportprojekte umzusetzen, sondern wir bieten auch spezielle Projekte für Firmen und soziale Initiativen an.</p>
            <p>Für Unternehmen bieten wir maßgeschneiderte Teambildungsmaßnahmen an, bei denen Kampfsport als Instrument zur Stärkung der Teamdynamik und Förderung der Zusammenarbeit eingesetzt wird. Unsere erfahrenen Trainer entwickeln individuelle Programme, die auf die spezifischen Bedürfnisse und Ziele des Unternehmens zugeschnitten sind. Ob in unseren Räumlichkeiten oder in den Büros der Firma - wir passen uns flexibel an, um das optimale Umfeld für <b>Ihr Team</b> zu schaffen.</p>
            <p>Darüber hinaus bieten wir <b>Kampfsport-Workshops</b> an, die speziell für Unternehmen konzipiert sind. Diese Workshops bieten eine einzigartige Gelegenheit, die Grundlagen verschiedener Kampfsportarten kennenzulernen und gleichzeitig körperliche Fitness, mentale Stärke und Selbstverteidigungsfähigkeiten zu verbessern. Unsere Trainer stehen Ihnen zur Seite, um Ihnen eine inspirierende und motivierende Erfahrung zu bieten.</p>
            <p>Für soziale Projekte, insbesondere für Kinder, bieten wir spezielle <b>Selbstverteidigungskurse</b> an. Unsere Kurse vermitteln den jungen Teilnehmern nicht nur effektive Selbstverteidigungstechniken, sondern auch Selbstvertrauen, Disziplin und Respekt. Wir können entweder in unseren Räumlichkeiten unterrichten oder zu den Räumlichkeiten der Organisation kommen, um den Kindern eine sichere Umgebung zu bieten, in der sie ihre Fähigkeiten entwickeln können.</p>
            <p>Kontaktieren Sie uns noch heute, um weitere Informationen zu unseren maßgeschneiderten Projekten für Firmen und soziale Initiativen zu erhalten. Wir freuen uns darauf, mit Ihnen zusammenzuarbeiten und ein einzigartiges und bereicherndes Erlebnis für <b>Ihr Team</b> oder Ihre soziale Gruppe zu schaffen.</p>
          </Col>
        </Row>
      </Container>

    </Layout>    
  );
}

export default Projekt;