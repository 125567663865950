import React from 'react';
import H1 from '../../../text-components/h1';
import style from './pagepath.module.scss';

class PagePath extends React.Component {
  render() {
    return (
      <div id="PagePath" className={style.pagePath}>
        <H1>
          {this.props.pagename}
        </H1>
      </div>
    );
  }    
}

export default PagePath;