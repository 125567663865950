import React from "react";
import { Row, Col } from "reactstrap";

import partner from "../../assets/partner";
import PicCard from '../../components/picCard';

import style from "./partner.module.scss";

class Partner extends React.Component {
  getRows = (data) => {
    let rows = [];
    data.forEach(p => {
      rows.push(
        <Col md={this.props.md ? this.props.md : 6} className={style.col}>

          <img src={p.image}
            alt={p.image}
            className={style.img}
          />          

          {p.h1 ? <div><h3> {p.h1 }</h3></div>: ''}  

          <div>         
            { p.text }            
          </div>
          <div>
            <a href={p.link} target="_blank" rel="noopener noreferrer">
              Zur Seite
            </a>
          </div>
        </Col>
      );
    });
    return rows;
  }

  getCards = (data) => {
    let rows = [];
    data.forEach(p => {
      rows.push(
        <PicCard 
          link={p.link}
          image={p.image}
          h1={p.h1}
          text={p.text}
          target="_blank"
          md={this.props.md ? this.props.md : 6}
        />
      );
    });
    return rows;    
  }

  render() {
    let Verbaende;
    let Gyms;
    let Other;
    if (this.props.asCard) {
      Verbaende = this.getCards(partner.partner.filter(a => a.type === 'Verband'));
      Gyms = this.getCards(partner.partner.filter(a => a.type === 'Gym'));
      Other = this.getCards(partner.partner.filter(a => a.type === 'Other'));
    } else {
      Verbaende = this.getRows(partner.partner.filter(a => a.type === 'Verband'));
      Gyms = this.getRows(partner.partner.filter(a => a.type === 'Gym'));
      Other = this.getRows(partner.partner.filter(a => a.type === 'Other'));
    }
    
    return (
      <div>
        <Row>
          <Col className={style.angebotCol}>
            <div className={style.firstH}>                
              <h2 invert='true'>Gyms & Vereine</h2>
            </div>            
          </Col>  
        </Row>
        <Row className={style.row}>          
          {Gyms}
        </Row>  

        <Row>
          <Col className={style.angebotCol}>
            <div className={style.firstH}>                
              <h2 invert='true'>Verbände</h2>
            </div>            
          </Col>  
        </Row>
        <Row className={style.row}>
          {Verbaende}
        </Row>  

        <Row>
          <Col className={style.angebotCol}>
            <div className={style.firstH}>                
              <h2 invert='true'>Weitere Partner</h2>
            </div>            
          </Col>  
        </Row>
        <Row className={style.row}>
          {Other}
        </Row>      
      </div>
    );
  }
}

export default Partner;