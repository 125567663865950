import React from 'react';
import { Switch, Route, Router } from "react-router-dom";
import { createBrowserHistory } from 'history';

import Home from '../../pages/home';
import Sports from '../../pages/sports';
import Trainer from '../../pages/trainer';
// import Timetable from '../../pages/timetable';
import Events from '../../pages/events';
import Preise from '../../pages/preise';
import Partner from '../../pages/partner';
import Contact from '../../pages/contact';
import Impressum from '../../pages/impressum';
import Projekt from '../../pages/projekt';
import Datenschutz from '../../pages/datenschutz';
import Maf from '../../pages/maf';
// import Belegungsplan from '../../pages/belegungsplan';
// import Calendar from '../../pages/calendar';
import Probetraining from '../../pages/probetraining';
// import AiTraining from '../../apps/aitraining';
// import NotFound from '../../pages/404';

class ContentRouter extends React.Component {
  render() {
    const history = createBrowserHistory();

    return (
      <Router history={history}>
        <Switch>
            <Route exact path="/">
              <Home />
            </Route>

            <Route path="/sports">
              <Sports />
            </Route>
            <Route path="/kurse">
              <Sports />
            </Route>

            <Route path="/trainer"> 
              <Trainer />
            </Route>
            {/* <Route path="/kursleiter"> 
              <Trainer />
            </Route> */}

            <Route path="/events"> 
              <Events />
            </Route>

            {/* <Route path="/timetable" component={Timetable}/>
            <Route path="/timetable/:id" component={Timetable}/> */}

            <Route path="/preise"> 
              <Preise />
            </Route>

            <Route path="/partner"> 
              <Partner />
            </Route>
            {/* <Route path="/verbände"> 
              <Partner />
            </Route> */}

            <Route path="/contact">
              <Contact />
            </Route>

            <Route path="/impressum">
              <Impressum />
            </Route>
            
            <Route path="/datenschutz">
              <Datenschutz />
            </Route>

            <Route path="/probetraining">
              <Probetraining />
            </Route>

            <Route path="/projekt">
              <Projekt />
            </Route>

            <Route path="/maf">
              <Maf />
            </Route>


            {/* <Route path="/calendar"  component={Calendar}/>

            <Route path="/belegungsplan"  component={Belegungsplan}/> */}

            {/* <Route path="/apps/aitraining"  component={AiTraining}/> */}

            <Route>
              <Home />
            </Route>
        </Switch>
      </Router>
    );
  }  
}

export default ContentRouter;