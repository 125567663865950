import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../../components/layout";
import style from "./trainer.module.scss";
import data from "../../assets/data.js";
import PagePath from '../../components/layout/header/pagepath';
import Partner from '../partner'

class Trainer extends React.Component {
  getTrainerFor = trainer => {
    let trainerFor = [];
    trainer.trainerFor.forEach(art => {
      let martialArt = data.martialArts.filter(
        martialArt => martialArt.anker === art
      )[0];
      trainerFor.push(<br />);
      trainerFor.push(<a href={"sports#" + martialArt.anker}> {martialArt.name} </a>);
    });
    return trainerFor;
  };

  getExperience = trainer => {
    let experience = [];
    trainer.experience.forEach(exp => {
      experience.push(
        <Row style={{borderTop: "1px solid #4b5156"}}>
          <Col xs="4" sm="4" md="4" lg="3">
            {exp.since && "Seit " + exp.since + " "}
            {exp.duration && exp.duration + " Jahre "}
          </Col>
          <Col>{exp.martialArt}</Col>
          {exp.rank ? <Col> {exp.rank} </Col> : ""}
        </Row>
      );
    });
    return experience;
  };

  getAwards = trainer => {
    let experience = [];
    if (!trainer.awards) return;
    trainer.awards.forEach(award => {
      experience.push(
        <Row style={{borderTop: "1px solid #4b5156"}}>
          <Col xs="4" sm="4" md="4" lg="3">
            {award.rank}
          </Col>
          <Col>
            {award.name}
          </Col>
        </Row>
      );
    });
    return experience;
  };

  getCerts = trainer => {
    let experience = [];
    if (!trainer.certs) return;
    trainer.certs.forEach(cert => {
      experience.push(
        <Row style={{borderTop: "1px solid #4b5156"}}>
          <Col>
            {cert.name}
          </Col>
        </Row>
      );
    });
    return experience;
  };

  getStatsNew = trainer => {
    let stats = [];
    if (!trainer.stats) return;
    trainer.stats.forEach(stat => {
      stats.push(
        <Row style={{borderTop: "1px solid #4b5156"}}>
          <Col xs="4" sm="4" md="4" lg="3">
            {stat.name}
          </Col>
          <Col>
            {stat.win}
          </Col>
          <Col>
            {stat.loss}
          </Col>
          <Col>
            {stat.draw}
          </Col>
        </Row>
      );
    });
    return stats;
  };

  getStats = trainer => {
    let stats = [];
    trainer.stats.forEach(stat => {
      stats.push(
        <span>
          <span style={{width: "50%", display: "inline-block", textAlign: "left"}}>{stat.name}</span>
          <span style={{width: "50%", display: "inline-block"}}>{stat.win + " - " + stat.loss + " - " + stat.draw}</span>
        </span>
      );
    });
    return stats;
  };

  createRows = () => {
    let rows = [];
    data.trainer.forEach((trainer, i) => {
      if (trainer.hideme) { return }
      const statsNew = this.getStatsNew(trainer);
      const awards = this.getAwards(trainer);
      const certs = this.getCerts(trainer);
      rows.push(
        <Col md={6} className={style.col}>
          <img src={require(`../../assets/images/${trainer.image}`)}
              alt={trainer.image}
              className={style.img}
          />

          {trainer.name ? <div><h3> {trainer.name} </h3></div> : ''}          

          <div>         
            { trainer.description }
            {trainer.link && trainer.linkname ? <a href={trainer.link} target="_blank" rel="noopener noreferrer"> {trainer.linkname} </a> : ''}
            <span className={style.textSpan}>Kurse: {this.getTrainerFor(trainer)}</span>
          </div>

          <hr className={style.angebotSpacer}/>   

          <div className={style.list}>   
             <span className={style.textSpan}><b>Erfahrung:</b> {this.getExperience(trainer)}</span>
             {awards && awards.length > 0 ? <span className={style.textSpan}> <b>Erfolge:</b> {awards}</span> : ""}
             {certs && certs.length > 0 ? <span className={style.textSpan}><b>Zertifikate:</b> {certs}</span> : ""}
             {statsNew && statsNew.length > 0 ? <span className={style.textSpan}><b>Statistik:</b> <span style={{fontSize: "small"}}>(Win - Loss - Draw)</span> {statsNew}</span> : ""}
           
          </div>
        </Col>
      );
    });
    return rows;
  };

  render() {
    return (
      <Layout pagename="Team">
        <Container>
          <Row className={style.row}>
            {this.createRows()}
          </Row>
        </Container>

        <Container fluid>
          <Row className={style.row}>
            <Col md="12" style={{padding:0}}>
              <PagePath pagename="Partner" />
            </Col>
          </Row>
        </Container>

        <Container>
          <Partner/>
        </Container>

      </Layout>
    );
  }
}

export default Trainer;
