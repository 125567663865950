import React from 'react';

export default { 
    "partner": [
        {
            "h1": "German Amateur Mixed Martial Arts Federation Mecklenburg Vorpommern e.V.",
            "h2": "",
            "image": require(`../assets/images/gammafmv.png`),
            "text": <span>
                Der German Amateur Mixed Martial Arts Federation Mecklenburg Vorpommern e.V. Kämpft für die Anerkennung des MMA-Sports in Deutschland, ohne Extremismus, der Hass oder Herabwürdigung von Menschen propagiert! 
                <br/>
                MMA erfreut sich weltweit immer größerer Beliebtheit. Es gibt keinen anderen Sport auf der Welt, der sich so schnell weiterentwickelt. Die Anzahl der Kämpfer und Fans wächst stetig. Die Struktur entfaltet sich in ungeahntem Ausmaß. Ob Clubs, Vereine oder Veranstaltungen und Veranstalter, ihre Zahl wird ständig größer und sie agieren immer professioneller und internationaler. Die Chancen und Möglichkeiten des MMA-Sports scheinen grenzenlos zu sein. Das Wachstum des MMA-Sports erfordert eine weltweit agierende Organisation, die alle Kräfte und das gesamte Potential des MMA-Sports in Deutschland bündelt, um so große Ziele gemeinsam zu erreichen.
                </span>,
            "link": "https://gammaf-mv.de/",
            "type": "Verband"
        },
        {
            "h1": "German Amateur Mixed Martial Arts Federation e.V.",
            "h2": "",
            "image": require(`../assets/images/gammaf.png`),
            "text": <span>
                MMA erfreut sich weltweit immer größerer Beliebtheit. Es gibt keinen anderen Sport auf der Welt, der sich so schnell weiterentwickelt. Die Anzahl der Kämpfer und Fans wächst stetig. Die Struktur entfaltet sich in ungeahntem Ausmaß. Ob Clubs, Vereine oder Veranstaltungen und Veranstalter, ihre Zahl wird ständig größer und sie agieren immer professioneller und internationaler. Die Chancen und Möglichkeiten des MMA-Sports scheinen grenzenlos zu sein. Das Wachstum des MMA-Sports erfordert eine weltweit agierende Organisation, die alle Kräfte und das gesamte Potential des MMA-Sports in Deutschland bündelt, um so große Ziele gemeinsam zu erreichen.
                <br/><br/>
                Die GAMMAF stellt sich dieser Herausforderung mit ihrem Dachverband, der GAMMA ,UWMMA, bereits in 40 Ländern. Zusammen mit allen Sportlern, Vereinen, Clubs und Trainern wollen wir eine gemeinschaftliche Institution für alle Interessierten und Gleichgesinnten schaffen, die den deutschen MMA-Sport weltweit vertritt und seiner Stimme international Geltung verleiht.
                </span>,
            "link": "https://gammaf.de/",
            "type": "Verband"
        },
        {
            "h1": "ZR-Team Greifswald",
            "h2": "",
            "address": "Karl-Liebknecht-Ring 26, 17491 Greifswald",
            "entfernung": "70 km",
            "häufigkeit": "Ungegrenzt",
            "image": require(`../assets/images/zr.jpg`),
            "text": <span>
                Wir freuen uns, dass unsere Mitglieder die exklusive Möglichkeit haben, <b>KOSTENLOS</b> in unserem Partnergym ZR Team Greifswald zu trainieren - und umgekehrt! Das ZR Team Greifswald wurde von der renommierten BJJ Weltmeisterin Gamila Kanew gegründet und bietet erstklassiges Training in verschiedenen Kampfsportdisziplinen.
                </span>,
            "link": "https://zrteam-greifswald.de/",
            "linkplan": "https://zrteam-greifswald.de/erwachsenentraining/",
            "type": "Gym"
        },
        {
            "h1": "Tengoku Dojo",
            "h2": "",
            "address": "Treptower Straße 1, 17033 Neubrandenburg",
            "entfernung": "2,7 km",
            "häufigkeit": "1 mal / Woche",
            "image": require(`../assets/images/tengoku.png`),
            "text": <span>
                Wir freuen uns, dass unsere Mitglieder die exklusive Möglichkeit haben, <b>KOSTENLOS</b> einmal pro Woche in unserem lokalen Partnergym Tengku Dojo zu trainieren - und umgekehrt! Das Tengku Dojo hat langjährige Erfahrung in den Bereichen Karate, Kickboxen, K1 und Muay Thai.
                </span>,
            "link": "https://tengoku-dojo.de/",
            "linkplan": "https://tengoku-dojo.de/trainingszeiten/",
            "type": "Gym"
        },
        {
            "h1": "Wendcore Fighting",
            "h2": "",
            "address": "Konsul-Wester-Straße 2, 29439 Lüchow",
            "entfernung": "212 km",
            "häufigkeit": "Unbegrenzt",
            "image": require(`../assets/images/wendcore.png`),
            "text": <span>
                Wir freuen uns, dass unsere Mitglieder die exklusive Möglichkeit haben, <b>KOSTENLOS</b> in unserem Partnergym Wendcore Fighting zu trainieren - und umgekehrt! Wendcore Fighting wird von dem Langjährigen MMA Trainer Daniel betrieben, welcher über 20 Jahre Kampfsport Erfahrung vorzuweisen hat.
                </span>,
            "link": "https://wendcore.de/",
            "linkplan": "https://www.instagram.com/wendcore.fighting/",
            "type": "Gym"
        },
        {
            "h1": "ZR Team Association",
            "h2": "",
            "image": require(`../assets/images/zr.png`),
            "text": <span>
                <p>ZR steht für Zé Radiola, den Gründer der ZR Team Association.</p>
                <p>Professor Zé gilt weltweit als einer der besten Trainer für BJJ und hat schon einige Weltmeister, hervorgebracht. Er war einer der führenden Köpfe der „Gracie Barra“ Organisation, die welweit über 400 Akademien hat. 2016 gründete Prof. Zé seine eigene Organisation – die ZR Association. Bereits jetzt hat die ZR Team Association über 100 Schulen. Und der erste Erfolg ließ nicht lange auf sich warten. Das ZR Team trat 2017 bei bei der Europameisterschaft mit einer vergleichsweise kleinen Mannschaft an und schaffte es trotzem den 3. Platz in der Teamwertung zu erzielen!</p>
                <p>Seither rangiert das ZR Team bei den beliebtesten Meisterschaften Europas unter den Erfolgskandidaten.</p>
            </span>,
            "link": "https://smoothcomp.com/en/affiliation/125",
            "type": "Verband"
        },
        {
            "h1": "BJJ GLOBETROTTERS",
            "h2": "",
            "image": require(`../assets/images/globetrotters.png`),
            "text": <span>
                BJJ GLOBETROTTERS IS A WORLD-WIDE COMMUNITY OF TRAVELERS AGAINST JIU JITSU POLITICS.
                <br/>
                We offer an alternative to traditional affiliation within Brazilian Jiu Jitsu. Membership of the community is open for anyone (both individuals and academies), and the main requirement to join is to spread—and agree with—our message.
                <br/>
                The members doesn’t represent a specific person, academy, instructor or lineage. They are merely a world-wide community of people, who share a common set of values:
                <br/>
                <ul style={{'text-align': 'left'}}>
                <li>We don’t pay each other any affiliation fees</li>
                <li>We wear any patches we like on our gis</li>
                <li>We are free to represent any (or no) team in competition</li>
                <li>We encourage training with anyone regardless of affiliation</li>
                <li>We are willing to promote anyone who deserves it—members or not</li>
                <li>We arrange camps, seminars and visit each other for training and fun</li>
                <li>We believe everyone is equal both on and off the mats</li>
                <li>We strive to enjoy life, people and the world through Brazilian Jiu Jitsu</li>
                </ul>
            </span>,
            "link": "https://www.bjjglobetrotters.com/",
            "type": "Verband"
        },
        {
            "h1": "Egym Wellpass",
            "h2": "Firmenfitness mit Wellpass",
            "image": require(`../assets/images/egym.png`),
            "text": <span>
                    Mit Wellpass bringst du mit über 6.500 Sport- und Wellnessangeboten Arbeit, Leben und persönliche Fitness in Einklang.
                    <br/>
                    Mit Wellpass kannst du so oft trainieren oder entspannen, wie du möchtest: checke ohne Limit in beliebig vielen Studios ein.
                </span>,
            "link": "https://egym-wellpass.com/mitglieder/",
            "type": "Other"
        },
        {
            "h1": "Gymdesk",
            "h2": "Gym management software that frees up your time and helps you grow.",
            "image": require(`../assets/images/gymdesk.png`),
            "text": <span>
                Simplified billing, enrollment, member management, and marketing features that help you grow your gym or martial arts school.
                </span>,
            "link": "https://gymdesk.com/a/Do1Wv",
            "type": "Other"
        },
        {
            "h1": "Printful",
            "h2": "Print-on-Demand-Dropshipping",
            "image": require(`../assets/images/printful.png`),
            "text": <span>
                Wir benutzen Printful gerne und wir denken, dir wird es auch gefallen. Sie bieten individualisierte Druckdienstleistungen an und können dir helfen, dein eigenes E-Commerce-Geschäft zu starten. Du musst dich nicht um die Lagerhaltung und den Versand der Produkte kümmern, denn das übernimmt Printful! Wenn du keinen eigenen Online-Shop erstellen möchtest, kannst du Printful nutzen, um individuell bedruckte Produkte mit deinen eigenen Designs zu bestellen. Wenn du dich über den Link <a href="https://www.printful.com/de/give-5-get-5/BNF15E" target="_blank" rel="noopener noreferrer">www.printful.com/de/give-5-get-5/BNF15E</a> anmeldest, erhalten wir beide 5 $, die wir auf Printful.com ausgeben können.              
                </span>,
            "link": "https://www.printful.com/de/give-5-get-5/BNF15E",
            "type": "Other"
        },
        {
            "h1": "MoJu Neubrandenburg",
            "h2": "Caritas Mecklenburg",
            "image": require(`../assets/images/caritas.png`),
            "text": <span>
                Die Mobile Jugendsozialarbeit der Caritas ist in verschiedenen Stadtteilen in Neubrandenburg unterwegs und sucht den Kontakt zu jugendlichen Cliquen.
                <br/>
                Die Mobile Jugendsozialarbeit der Caritas ist in verschiedenen Stadtteilen in Neubrandenburg und in Penzlin unterwegs und sucht den Kontakt zu jugendlichen Cliquen, in besonderen Fällen auch zu einzelnen Jugendlichen, die sich auf der Straße, auf öffentlichen Plätzen oder anderen selbstgewählten Räumen treffen, um sie zu begleiten und zu unterstützen. Dabei sind die Mitarbeiter lediglich Gäste in der Lebenswelt der Jugendlichen. Die Jugendlichen entscheiden immer selbst, ob sie das Angebot annehmen. Altersmäßig richtet sich das Angebot an Kinder mit jugendspezifischem Verhalten sowie an Jugendliche und junge Heranwachsende. Das Angebot richtet sich an Kinder mit jugendspezifischem Verhalten sowie an Jugendliche und junge Heranwachsende.
                </span>,
            "link": "https://www.caritas-mecklenburg.de/caritas-vor-ort/region-neubrandenburg/kinder-und-jugendliche/moju-neubrandenburg/moju-neubrandenburg",
            "type": "Other"
        },
        {
            "h1": "Jugendmigrationsdienst Neubrandenburg",
            "h2": "AWO Stadtverband Neubrandenburg e.V.",
            "image": require(`../assets/images/jmd-logo.png`),
            "text": <span>
               Über 470 Jugendmigrationsdienste (JMD) bundesweit begleiten junge Menschen mit Migrationshintergrund im Alter von 12 bis 27 Jahren mittels individueller Angebote und professioneller Beratung bei ihrem schulischen, beruflichen und sozialen Integrationsprozess in Deutschland. Individuelle Unterstützung, Gruppen- und Bildungsangebote sowie eine intensive Vernetzung mit Schulen, Ausbildungsbetrieben, Integrationskursträgern und anderen Einrichtungen der Jugendhilfe zählen zu den wesentlichen Aufgaben der JMD.
               </span>,
            "link": "https://www.jugendmigrationsdienste.de/jmd/neubrandenburg/",
            "type": "Other"
        },
        {
            "h1": "Die Bildungskarte",
            "h2": "Landkreis / Jobcenter Mecklenburgische Seenplatte",
            "image": require(`../assets/images/bildungskarte.png`),
            "text": <span>
                    Die Bildungs Karte ist die einfache und praktische Lösung zur Umsetzung des Bildungs- und Teilhabepakets. Ein bereitgestelltes Teilhabebudget ermöglicht eine geförderte Beteiligung an diversen Vereins-, Kultur-, Freizeit und Nachhilfeangeboten.
                    <br/>
                    Die zuständigen Ämter (Kommunen bzw. Jobcenter oder Optionskommunen) stellen Leistungsberechtigten eine elektronische Karte zur Verfügung, die für die Zahlung der bewilligten Leistungen genutzt werden kann.
                    <br/>
                    Die teilnehmenden Leistungserbringer/-anbieter (z.B. Musik- und Sportvereine, Nachhilfelehrer etc.) rechnen die in Anspruch genommenen Leistungen mit den entsprechenden Ämtern ab, wobei die Firma Sodexo Pass GmbH diese Aufgabe für die betreffenden Ämter übernimmt.
                </span>,
            "link": "https://www.bildungs-karte.org/pages/public/info.php",
            "type": "Other"
        },
    ]
}