import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from '../../components/layout';

import style from './datenschutz.module.scss';


function Datenschutz() {
  return (
    <Layout pagename="Datenschutzerklärung">
      <Container  className={style.container}>
        <Row className={style.row}>
          <Col className={style.col}>
            <p>Verantwortlich im Sinne des Datenschutzgesetzes:</p>

            <p>
              Attitude Sports NB - Martial Arts Academy <br/>
              Dennis Schulz <br/>
              Kuhdamm 3a <br/>
              17033 Neubrandenburg <br/>
              01517 0099473 <br/>
              <a href="mailto:mail@attitudesports.de">info@attitudesports.de</a>
            </p>

            <h2>Erfassung von Daten</h2>

            <p>Während Sie auf unsere Webseiten zugreifen, erfassen wir automatisch Daten von allgemeiner Natur. Diese Daten (Server-Logfiles) umfassen zum Beispiel die Art ihres Browsers, ihr Betriebssystem, den Domainnamen Ihres Internetanbieters sowie weitere ähnliche allgemeine Daten. Diese Daten sind absolut Personen unabhängig und werden genutzt, um Ihnen die Webseiten korrekt darzustellen und werden bei jeder Nutzung des Internets abgerufen. Die absolut anonymen Daten werden statistisch ausgewertet um unseren Service für Sie zu verbessern.</p>

            <h2>Anmeldung auf unserer Webseite</h2>

            <p>Bei der Anmeldung für spezifische Angebote erfragen wir einige persönliche Daten wie Name, Anschrift, Kontakt, Telefonnummer oder E-Mail-Adresse. Angemeldete Nutzer können auf bestimmte Zusatzleistungen zugreifen. Angemeldete Nutzer haben die Möglichkeit, alle angegebenen persönlichen Daten zu jedem Zeitpunkt zu ändern oder löschen zu lassen. Sie können auch jederzeit die von ihnen gespeicherten Daten bei uns erfragen. Soweit gesetzlich keine Frist für die Aufbewahrung der Daten besteht, können diese geändert oder gelöscht werden. Bitte kontaktieren Sie uns dazu über unsere Kontaktseite.</p>

            <h2>Newsletter</h2>

            <p>Wenn Se sich für unseren Newsletter anmelden nutzen wir die Anmeldedaten ausschließlich für den Newsletter-Dienst. Angemeldete Nutzer können von uns kontaktiert werden um über Änderungen, Aktualisierungen oder andere relevante Informationen unterrichtet zu werden.</p>

            <p>Bei der Anmeldung muss eine gültige E-Mail-Adresse angegeben werden. Über das sogenannte „double-opt-in“ Verfahren wird geprüft, ob Anmelder und E-Mail übereinstimmen.</p>

            <p>Sie haben das Recht, ihre Daten für den Newsletterdienst jederzeit löschen zu lassen. In unseren Newslettern finden Sie einen Link zur Abmeldung. Außerdem können Sie sich auf der Webseite abmelden oder uns ihren Wunsch über unsere Kontaktseite mitteilen.</p>

            <h2>Kontaktformular</h2>

            <p>Wenn Sie uns über unsere Kontaktseite kontaktieren oder uns eine E-Mail schicken werden die entsprechenden Daten zur Bearbeitung gespeichert.</p>

            <h2>Löschung oder Sperrung von Daten</h2>

            <p>Ihre persönlichen Daten werden nur so lange gespeichert, wie dies absolut notwendig ist um die angegebenen Dienste zu leisten und es vom Gesetz vorgeschrieben ist. Nach Ablauf dieser Fristen werden die persönlichen Daten der Nutzer regelmäßig gesperrt oder gelöscht.</p>

            <h2>Google Analytics</h2>

            <p>Unsere Webseite nutzt Google Analytics, den Webanalysedienst der Google Inc. („Google“). Google Analytics nutzt „Cookies“, kleine Textdateien, die auf Ihrem Rechner gespeichert werden und Daten zur Nutzung unserer Webseite speichern. Die vom Cookie erzeugten Daten über die Nutzung unserer Webseite werden an einen Google Server in die USA übertragen und gespeichert. Da auf unseren Webseiten eine IP-Anonymisierung aktiviert ist, wird Ihre IP-Adresse von Google gekürzt. Dies gilt für Mitgliedstaaten der Europäischen Union und anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum. In seltenen Fällen kann es vorkommen, dass die volle IP-Adresse an einen Google Server in den USA übertragen und erst dort gekürzt wird. Google nutzt diese Informationen um Ihre Nutzung unserer Webseite auszuwerten, um für uns Berichte über die Nutzung der Webseite zusammenzustellen und um weitere statistische Daten zu erheben. Ihre IP-Adresse wird dabei niemals mit anderen auf Google gespeicherten Daten zusammengeführt. Die Verwendung von Cookies kann durch eine Einstellung in ihrem Browser verboten werden; dies kann jedoch unter Umständen die Funktion unserer Webseite beeinträchtigen. Mittels eines speziellen Zusatzprogramms für ihren Browser (Add On) können Sie die Sammlung von Daten durch Google Analytics desaktivieren.</p>

            <h2>Google Remarketing</h2>

            <p>Unsere Webseite verwendet die Google Remarketing-Funktion die dazu dient, Besuchern von Webseiten die zum Google-Werbenetzwerks gehören individuelle Werbung zu zeigen. In ihrem Browser wird ein „Cookie“ gespeichert, mit dem sie wiedererkannt werden können, wenn Sie eine Webseite aufrufen, die zum Google Werbenetzwerk gehört. Dabei können Sie Werbung präsentiert bekommen, die mit dem Besuch von anderen Webseiten in Zusammenhang stehen, die ebenfalls die Google Remarketing Funktion verwenden.</p>

            <p>Google speichert dabei keine individuellen Daten. Unter <a href="http://www.google.com/settings/ads">http://www.google.com/settings/ads</a> haben Sie die Möglichkeit, die Funktion von Google Remarketing zu desaktivieren. Der Einsatz von Cookies für individuelle Werbeschaltung kann ebenfalls abgestellt werden. Wie das geht beschreibt dieser Link: <a href="http://www.networkadvertising.org/managing/opt_out.asp">http://www.networkadvertising.org/managing/opt_out.asp</a> folgen.</p>

            <h2>Social Plugins</h2>

            <p>Wir verwenden auf unserer Webseiten Social Plugins der im Folgenden aufgelisteten Anbieter. Plugins sind mit dem entsprechenden Logo gekennzeichnet.</p>

            <p>Der Dienstbetreiber kann unter Umständen durch solche Plugins Informationen und personenbezogene Daten unserer Besucher erhalten. Eine von uns installierte 2-Klick-Lösung verhindert die nicht offensichtliche Erfassung und Übertragung von Daten an den Dienstanbieter. Nur wenn ein Social Plugin von Ihnen per Klick aktiviert wird, wird die Sammlung von Informationen und deren Versand an den Dienstanbieter ausgelöst.</p>

            <p>Welche Daten ein solches Plugin erfasst sobald es aktiviert wurde, und wie diese anschließend verwendet werden, liegt außerhalb unseres Einflusses. In einigen Fällen werden direkte Verbindungen zu den Diensten des jeweiligen Anbieters erstellt und die IP-Adresse und spezifische Nutzungs-Informationen können dabei erfasst und genutzt werden. Dienstanbieter können auch versuchen, Cookies auf ihrem Rechner zu speichern. Wie diese Cookies arbeiten erfahren Sie in den Datenschutzhinweisen des entsprechenden Anbieters. Von Facebook können Sie als Besucher identifiziert werden, sofern sie aktuell in ihrem Facebook Account angemeldet sind.</p>

            <p>Social-Media-Buttons der folgenden Unternehmen sind auf unserer Webseite eingebunden:</p>

            <p>Google Plus/Google Inc. (1600 Amphitheatre Parkway – Mountain View – CA 94043 – USA)</p>

            <h2>Cookies</h2>

            <p>Unsere Webseite verwendet „Cookies“. Cookies sind Textdateien, die vom Server einer Webseite auf Ihren Rechner übertragen werden. Bestimmte Daten wie IP-Adresse, Browser, Betriebssystem und Internet Verbindung werden dabei übertragen.</p>

            <p>Cookies starten keine Programme und übertragen keine Viren. Die durch Cookies gesammelten Informationen dienen dazu, Ihnen die Navigation zu erleichtern und die Anzeige unserer Webseiten zu optimieren.</p>

            <p>Daten, die von uns erfasst werden, werden niemals ohne Ihre Einwilligung an Daten an Dritte weitergegeben oder mit personenbezogenen Daten verknüpft.</p>

            <p>Die Verwendung von Cookies kann über Einstellungen in ihrem Browser verhindert werden. In den Erläuterungen zu Ihrem Internetbrowsers finden Sie Informationen darüber, wie man diese Einstellungen verändern kann. Einzelne Funktionen unserer Website können unter Umständen nicht richtig funktionieren, wenn die Verwendung von Cookies desaktiviert ist.</p>

            <h2>Google AdWords</h2>

            <p>Unsere Webseite verwendet Google Conversion-Tracking. Wenn Sie durch den Klick auf eine Google-Anzeige auf unsere Webseite gelangt sind, speichert Google Adwords ein Conversion-Tracking Cookie auf Ihrem Computer. Nach 30 Tagen verlieren diese Cookies ihre Gültigkeit, Webseitenbesucher werden dabei nicht identifiziert. Innerhalb der 30 Tage Spanne können wir und Google feststellen, dass der Nutzer anfangs auf die Anzeige geklickt um auf unsere Seite zu landen. Google AdWords- Cookies sind kundenspezifisch und können nicht über die Webseiten von AdWords-Kunden nachverfolgt werden. Die mit Conversion-Cookies gesammelten Informationen werden dazu genutzt, Conversion-Statistiken für AdWords-Kunden zu erstellen, die Conversion-Tracking nutzen. Die Kunden erfahren dabei, wie viele Nutzer die eine Anzeige genutzt haben um ihre Seite zu besuchen. Persönliche Daten der Nutzer werden dabei nicht übermittelt.</p>

            <p>Wer sich nicht am Tracking beteiligen möchte kann dies in den Browser Einstellungen festlegen. Das automatische Setzen von Cookies kann generell desaktiviert werden oder Sie können Ihren Browser so einstellen, dass googleleadservices.com Cookies immer blockiert werden.</p>

            <p>Opt-out-Cookies dürfen nicht gelöscht werden, wenn Sie die Aufzeichnung von Messdaten verweigern möchten. Wenn Sie die Cookies in Ihrem Browser löschen, müssen alle Opt-out Cookies neu gesetzt werden.</p>

            <h2>Auskunft, Berichtigung, Sperre, Löschung und Widerspruch</h2>

            <p>Sie können zu jedem Zeitpunkt Informationen über Ihre bei uns gespeicherten Daten erbitten. Diese können auch berichtigt, gesperrt oder, sofern die vorgeschriebene Zeiträume der Datenspeicherung zur Geschäftsabwicklung abgelaufen sind, gelöscht werden. Unser Datenschutzmitarbeiter hilft Ihnen bei allen entsprechenden Fragen. Seine Kontaktdaten finden Sie weiter unten.</p>

            <p>Gesperrte Daten werden in einer speziellen Sperrdatei zu Kontrollzwecken verwahrt. Wenn die gesetzliche Archivierungsverpflichtung abgelaufen ist können Sie auch die Löschung der Daten verlangen. Innerhalb der Archivierungsverpflichtung können wir Ihre Daten auf Wunsch sperren.</p>

            <p>Änderungen oder Widerruf von Einwilligungen können durch eine Mitteilung an uns vorgenommen werden. Dies ist auch für zukünftige Aktionen möglich.</p>

            <h2>Änderung der Datenschutzbestimmungen</h2>

            <p>Unsere Datenschutzerklärung können in unregelmäßigen Abständen angepasst werden, damit sie den aktuellen rechtlichen Anforderungen entsprechen oder um Änderungen unserer Dienstleistungen umzusetzen, z. B. bei der Einfügung neuer Angebote. Für Ihren nächsten Besuch gilt dann automatisch die neue Datenschutzerklärung.</p>

            <h2>Kontakt zum Datenschutzmitarbeiter</h2>

            <p>Für Fragen zum Datenschutz schicken Sie uns bitte eine Nachricht an <a href="mailto:mail@attitudesports.de">mail@attitudesports.de</a> mit dem Betreff „Datenschutz“.</p>

            <p>Diese Widerrufsbelehrung Seite wurde bei datenschutzerklärunggenerator.de erstellt.ssung wird der Datenschutz stets berücksichtigt. Diese ermittelt statistisch die Nutzungsintensität und die Zahl der Nutzer einer Website. Dabei werden einzelne Nutzer nicht erkannt, die Identität bleibt stets geschützt. Das System versendet keine Werbung.</p>

            <p>Web-Angebote, die zur Informationsgemeinschaft zur Feststellung der Verbreitung von Werbeträgern e.V. (IVW – <a href="http://www.ivw.eu">http://www.ivw.eu</a>) gehören oder an der Studie „internet facts“ der Arbeitsgemeinschaft Online-Forschung e.V. (AGOF – <a href="www.agof.de">www.agof.de</a>) teilnehmen, werden die Nutzungsstatistiken monatlich von der AGOF und der Arbeitsgemeinschaft Media-Analyse e.V. (ag.ma – <a href="www.agma-mmc.de">www.agma-mmc.de</a>), sowie der IVW veröffentlicht und können unter <a href="http://www.agof.de">http://www.agof.de</a>, <a href="http://www.agma-mmc.de">http://www.agma-mmc.de</a> und <a href="http://www.ivw.eu">http://www.ivw.eu</a> eingesehen werden, . die IVW überprüft das SZM-Verfahren regelmäßig im Hinblick auf eine regel- und datenschutzkonforme Nutzung, außerdem werden die Messdaten veröffentlicht.</p>

            <p>Die Website der INFOnline GmbH <a href="https://www.infonline.de">https://www.infonline.de</a>), die Datenschutzwebsite der AGOF (<a href="http://www.agof.de/datenschutz">http://www.agof.de/datenschutz</a>) und die Datenschutzwebsite der IVW (<a href="http://www.ivw.eu">http://www.ivw.eu</a>) bieten detaillierte Informationen zum SZM-Verfahren.</p>

            <p>Sollten Sie der Datenverarbeitung durch das SZM nicht entsprechen, können Sie dies unter den folgenden Links kundtun: <a href="http://optout.ioam.de">http://optout.ioam.de</a> und <a href="http://optout.ivwbox.de">http://optout.ivwbox.de</a></p>

            <h2>Google AdWords</h2>

            <p>Unsere Webseite verwendet Google Conversion-Tracking. Wenn Sie durch den Klick auf eine Google-Anzeige auf unsere Webseite gelangt sind, speichert Google Adwords ein Conversion-Tracking Cookie auf Ihrem Computer. Nach 30 Tagen verlieren diese Cookies ihre Gültigkeit, Webseitenbesucher werden dabei nicht identifiziert. Innerhalb der 30 Tage Spanne können wir und Google feststellen, dass der Nutzer anfangs auf die Anzeige geklickt um auf unsere Seite zu landen. Google AdWords- Cookies sind kundenspezifisch und können nicht über die Webseiten von AdWords-Kunden nachverfolgt werden. Die mit Conversion-Cookies gesammelten Informationen werden dazu genutzt, Conversion-Statistiken für AdWords-Kunden zu erstellen, die Conversion-Tracking nutzen. Die Kunden erfahren dabei, wie viele Nutzer die eine Anzeige genutzt haben um ihre Seite zu besuchen. Persönliche Daten der Nutzer werden dabei nicht übermittelt.</p>

            <p>Wer sich nicht am Tracking beteiligen möchte kann dies in den Browser Einstellungen festlegen. Das automatische Setzen von Cookies kann generell desaktiviert werden oder Sie können Ihren Browser so einstellen, dass googleleadservices.com Cookies immer blockiert werden.</p>

            <p>Opt-out-Cookies dürfen nicht gelöscht werden, wenn Sie die Aufzeichnung von Messdaten verweigern möchten. Wenn Sie die Cookies in Ihrem Browser löschen, müssen alle Opt-out Cookies neu gesetzt werden.</p>

            <h2>Auskunft, Berichtigung, Sperre, Löschung und Widerspruch</h2>

            <p>Sie können zu jedem Zeitpunkt Informationen über Ihre bei uns gespeicherten Daten erbitten. Diese können auch berichtigt, gesperrt oder, sofern die vorgeschriebene Zeiträume der Datenspeicherung zur Geschäftsabwicklung abgelaufen sind, gelöscht werden. Unser Datenschutzmitarbeiter hilft Ihnen bei allen entsprechenden Fragen. Seine Kontaktdaten finden Sie weiter unten.</p>

            <p>Gesperrte Daten werden in einer speziellen Sperrdatei zu Kontrollzwecken verwahrt. Wenn die gesetzliche Archivierungsverpflichtung abgelaufen ist können Sie auch die Löschung der Daten verlangen. Innerhalb der Archivierungsverpflichtung können wir Ihre Daten auf Wunsch sperren.</p>

            <p>Änderungen oder Widerruf von Einwilligungen können durch eine Mitteilung an uns vorgenommen werden. Dies ist auch für zukünftige Aktionen möglich.</p>

            <h2>Änderung der Datenschutzbestimmungen</h2>

            <p>Unsere Datenschutzerklärung können in unregelmäßigen Abständen angepasst werden, damit sie den aktuellen rechtlichen Anforderungen entsprechen oder um Änderungen unserer Dienstleistungen umzusetzen, z. B. bei der Einfügung neuer Angebote. Für Ihren nächsten Besuch gilt dann automatisch die neue Datenschutzerklärung.</p>

            <h2>Kontakt zum Datenschutzmitarbeiter</h2>

            <p>Für Fragen zum Datenschutz schicken Sie uns bitte eine Nachricht an <a href="mailto:mail@attitudesports.de">mail@attitudesports.de</a> mit dem Betreff „Datenschutz“.</p>
          </Col>
        </Row>
      </Container>
    </Layout>    
  );
}

export default Datenschutz;