import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import styles from './headLine.module.scss';

class HeadLine extends React.Component {
  render() {
    return (
      <Container fluid style={{color: '#ddd'}}>
        {/* <Row>
          <Col>
            <Button>
              Überzeugt? Jetzt Probetraining vereinbaren!
            </Button>
          </Col>
        </Row> */}
        <Row className={styles.row}>
          <Col className={styles.row}>
            {this.props.link ? 
            <a href={this.props.link}>
              <h2 className={styles.center}>{this.props.text}</h2>
            </a> : 
            <h2 className={styles.center}>{this.props.text}</h2>}
            
          </Col>                
        </Row>
      </Container>
    )
  }
}

export default HeadLine;