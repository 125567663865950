import React from 'react';
import {Row, Col, Container, Button} from 'reactstrap'
import Layout from '../../components/layout/layout';
import queryString from 'query-string';
import styles from './events.module.scss';
import Scheduler, {View} from 'devextreme-react/scheduler';
import CustomStore from 'devextreme/data/custom_store';
import ReactHtmlParser from 'react-html-parser'; 
import TimeTable from '../../components/timetable';
import HeadLine from '../../components/headLine';
import partner from "../../assets/partner";

function getData(_, requestOptions) {
  const PUBLIC_KEY = 'AIzaSyAqMhfp5InoVyt0y5JcX1E3tnIEvesM--k';
  const CALENDAR_ID = 'v4b3571b71tqneaaus63rs4u6o@group.calendar.google.com';
  const dataUrl = ['https://www.googleapis.com/calendar/v3/calendars/',
    CALENDAR_ID, '/events?key=', PUBLIC_KEY].join('');

  return fetch(dataUrl, requestOptions).then(
    (response) => response.json(),
  ).then((data) => data.items);
}

const dataSource = new CustomStore({
  load: (options) => getData(options),
});

function renderDateCell(cellData) {
  return (
    <React.Fragment>
      <div className="name">{cellData.date.toLocaleDateString()}</div>
    </React.Fragment>
  );
}

const currentDate = new Date();
// const views = ['month',{ name: 'List', type: 'agenda', agendaDuration: 365, renderDateCell },];

class Timetable extends React.Component {
  constructor(props) {
    super();

    this.locationSearch = queryString.parse(window.location.search);   
  }  

  getCards = (data) => {
    let rows = [];
    data.forEach(p => {
      rows.push(
        <Row>
          <Col md={2}>
            <p>
              <div className={styles.partnerImg} style={{backgroundImage: `url(${p.image})` }}></div>
            </p>            
          </Col>
          <Col>
            <h4>
              {p.h1}
            </h4>
            <p>
              Adresse: {p.address}
            </p>
            <p>
              Entfernung zu uns: {p.entfernung}
            </p>
            <p>
              Kostenlose Trainings: {p.häufigkeit}
            </p>
            <a href={p.linkplan}>
              {p.linkplan}
            </a>
          </Col>
        </Row>
      );
    });
    return rows;    
  }

  render() {
    let Gyms = this.getCards(partner.partner.filter(a => a.type === 'Gym'));

    return (
      
      <Layout pagename="Kursplan">
        <Container>
          <Row>
            <Col>
              <TimeTable params={this.locationSearch}/>   
            </Col>
          </Row>
        </Container>

        <HeadLine 
          text="Partnergyms"
        />

        <Container>
          <h3 className={styles.partnerh3}>Trainiere <b>kostenlos</b> in unseren Partnergyms!</h3>
          {Gyms}
        </Container>

        <HeadLine 
          text="Events"
        />

        <Container>
          <Row>
            <Col>
              <Scheduler
                dataSource={dataSource}
                // views={views}
                defaultCurrentView="agenda"
                defaultCurrentDate={currentDate}
                height={500}
                agendaDuration={7}
                firstDayOfWeek={1}
                startDayHour={0}
                editing={false}
                showAllDayPanel={false}
                startDateExpr="start.dateTime"
                endDateExpr="end.dateTime"
                textExpr="summary"
                timeZone="Europe/Berlin"
                appointmentTooltipComponent={AppointmentTooltip}
                appointmentComponent={Appointment}
                onAppointmentFormOpening={(e)=>{e.cancel = true}}
              >
                <View
                  name="Monat"
                  type="month"
                />
                 <View
                  name="Liste"
                  type="agenda"
                  agendaDuration="365"
                  dateCellRender={renderDateCell}
                />
              </Scheduler>
            </Col>
          </Row>     
          <Row>
            <Col className={styles.legend}>
              <div className={styles.square} style={{backgroundColor: '#1ca8dd'}} />
              <div>Inhouse</div>
              <div className={styles.square} style={{backgroundColor: '#d8dd8a'}} />
              <div>Auswärts</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className={styles.white}>Calender hinzufügen: </h3>
              <Button className={styles.dlBtn} onClick={()=>{window.open("https://calendar.google.com/calendar/ical/v4b3571b71tqneaaus63rs4u6o%40group.calendar.google.com/public/basic.ics")}}>iCal</Button>
              <Button className={styles.dlBtn} onClick={()=>{window.open('https://calendar.google.com/calendar/embed?src=v4b3571b71tqneaaus63rs4u6o%40group.calendar.google.com&ctz=Europe%2FBerlin')}}>Google</Button>
            </Col>
          </Row>
        </Container>   
      </Layout>
    );
  }  
}

export default Timetable;

export class Appointment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const we = this.props.data.appointmentData.location?.includes('Attitude Sports NB - Martial Arts Academy')
    return (
      <div className={we ? styles.we : styles.notwe}>
            {this.props.data.appointmentData.summary}
      </div>
    );
  }
}

export class AppointmentTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const start = new Date(this.props.data.appointmentData.start.dateTime);
    const end = new Date(this.props.data.appointmentData.end.dateTime)
    return (
      <Container style={{whiteSpace: 'pre', textAlign: 'left'}}>
        <Row>
          <Col>
            <b>{this.props.data.appointmentData.summary}</b>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            Ort: 
          </Col>
          <Col md={9}>
            {this.props.data.appointmentData.location?.split(', ')?.join('\n')}
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            Start: 
          </Col>
          <Col md={9}>
            {start?.toLocaleDateString('de-DE') + ' ' + start?.toLocaleTimeString('de-DE')}
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            Ende: 
          </Col>
          <Col md={9}>
            {end?.toLocaleDateString('de-DE') + ' ' + end?.toLocaleTimeString('de-DE')}
          </Col>
        </Row>
        <Row>
          <Col className={styles.description}>
            <i>{ ReactHtmlParser(this.props.data.appointmentData.description)}</i>
          </Col>
        </Row>
      </Container>
    );
  }
}