import React from 'react';

export default { 
    "things": [
        {
            "image": require(`../assets/images/8 Gründe 1.png`),
            "text": <span><b>Selbstverteidigung</b> - Mit unserem Kampfsport lernst du, dich selbst zu verteidigen und sicherer in potenziell gefährlichen Situationen zu fühlen.</span>
        },
        {
            "image": require(`../assets/images/8 Gründe 2.png`),
            "text": <span><b>Fitness & Gesundheit</b> - Du wirst durch unser Training fitter und gesünder, da es deine Ausdauer, Kraft und Flexibilität verbessert.</span>
        },
        {
            "image": require(`../assets/images/8 Gründe 3.png`),
            "text": <span><b>Disziplin & Selbstbeherrschung</b> - Unsere Kampfkunst lehrt dich Disziplin und Selbstkontrolle, was dir auch außerhalb des Trainings zugutekommen kann.</span>
        },
        {
            "image": require(`../assets/images/8 Gründe 4.png`),
            "text": <span><b>Stressabbau</b> - Unser Training hilft dir, Stress abzubauen und dich mental zu erfrischen.</span>
        },
        {
            "image": require(`../assets/images/8 Gründe 5.png`),
            "text": <span><b>Steigerung des Selbstbewusstseins</b> - Du wirst Selbstvertrauen aufbauen, wenn du in unserer Kampfkunst Fortschritte machst.</span>
        },        
        {
            "image": require(`../assets/images/8 Gründe 6.png`),
            "text": <span><b>Unglaubliche Gemeinschaft</b> - In unserer Kampfsportgemeinschaft knüpfst du soziale Bindungen und lernst andere mit ähnlichen Interessen kennen.</span>
        },
        {
            "image": require(`../assets/images/8 Gründe 7.png`),
            "text": <span><b>Verbesserung der mentalen Fähigkeiten</b> - Durch unser Training schärfst du deine Konzentrationsfähigkeit und stärkst deine geistige Ausdauer.</span>
        },
        {
            "image": require(`../assets/images/8 Gründe 8.png`),
            "text": <span><b>Spass & Herausforderung</b> - Mit unserer Kampfkunst erlebst du nicht nur Spaß, sondern auch die ständige Herausforderung, neue Fähigkeiten zu erlernen und zu verbessern.</span>
        },
    ]
}