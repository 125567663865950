import React from 'react';
import { Button, Container, Row, Col } from 'reactstrap';
import style from './timeTable.module.scss';
import data from '../../assets/data.js';
import * as htmlToImage from 'html-to-image';

class TimeTable extends React.Component {
  constructor(props) {
    super(props)

    this.time = {
      minTime: undefined,
      maxTime: undefined,
    }
    this.state = {
      isMobile: false,
      filter: props.params.filter
    }
    this.martialArts = data.martialArts;
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();

    let tab = document.getElementsByClassName(style.row)[0];
    htmlToImage.toJpeg(tab).then(function (dataUrl) {
      let meta = document.createElement("meta");
      meta.setAttribute('property', 'og:image');
      meta.setAttribute('content', dataUrl);
      document.head.appendChild(meta);
    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
  }

  downloadTimeTable = () => {
    let tab = document.getElementsByClassName('plan')[0];
    htmlToImage.toJpeg(tab).then(function (dataUrl) {
      var link = document.createElement('a');
      link.download = 'Trainingsplan.jpeg';
      link.href = dataUrl;
      link.click();
    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
  }

  resize = () => {
    this.setState({
      isMobile: window.innerWidth < 992
    });
  }

  filter = () => {
    if (this.state.filter) {
      let martialArts = data.martialArts.filter(martialArt => martialArt.kategorie === this.state.filter);
      this.martialArts = martialArts.length > 0 && martialArts[0].time ? martialArts : data.martialArts;
    } else {
      this.martialArts = data.martialArts;
    }
  }

  toTimeString = (time) => {
    let hour = time.toString().padStart(4, "0").substring(0, 2);
    let min = (time.toString().substring(2, 4) * 0.6).toString();
    min.length < 2 && (min = min.toString() + "0");

    return hour + ":" + min;
  }

  createMartialArtsDivs = (day) => {
    let martialArtsDivs = [];
    this.martialArts.forEach((martialArt, j) => {
      if (martialArt.hideInTimetable) return;

      let times = martialArt.time && martialArt.time.filter(time => time.day === day.id);
      times && times.forEach((time, k) => {
        let maStyle = {
          height: this.state.isMobile ? '100px' : (time.end - time.start) / 2 , // time.end - time.start
          top: this.state.isMobile ? 'auto' : (time.start - this.time.minTime) / 2 + 50,
          position: this.state.isMobile ? 'relative' : 'absolute',
          fontSize: 'small',
          ...martialArt.style
        };
        
        martialArtsDivs.push(
          <a key={time.day + "_" + time.start} starttime={time.start.toString()} href={"sports#" + martialArt.anker} className={style.maCell} style={maStyle}> 
            <div className={style.maCellDiv}>
            { 
              martialArt.name             
            }
            <br/>
            { 
              time.subText ? 
              <span className={style.subText}>{ time.subText } <br/> </span> :
              ''
            }
            {
              time.hidetime ?
              '' :
              <span className={style.timeText}>{ this.toTimeString(time.start) + " - " + this.toTimeString(time.end) } </span>   
            }
              
            </div>
          </a>
        );
      });
    });

    martialArtsDivs.sort(function(a, b) {
      var nameA = a.key;
      var nameB = b.key;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    return martialArtsDivs; 
  }

  createDayCols = () => {
    let dayCols = [];
    data.days.forEach((day, i) => {
      let dayColDivs = [<div key="DaysColHead" className={style.headerCell}> { day.name } </div>];

      for (var time = this.time.minTime; time < this.time.maxTime; time += 100) {
        dayColDivs.push(<div key={day.name + this.toTimeString(time)} className={[style.cell, "d-none d-lg-block"].join(' ')}> </div>);
      }

      dayColDivs.push(...this.createMartialArtsDivs(day));

      dayCols.push(<Col key={"DayCol" + day.name} xs="12" sm="12" md="12" lg="" className={style.col}> { dayColDivs } </Col>);      
    });

    return dayCols
  }

  createTimeCol = () => {
    let timeColDivs = [<div key="TimeColHead" className={style.headerCell}> Zeiten </div>];
    for (var time = this.time.minTime; time < this.time.maxTime; time += 100) {
      timeColDivs.push(<div key={this.toTimeString(time)} className={style.firstColCell}> { this.toTimeString(time) } Uhr </div>)
    }
    return <Col key="TimeCol" xs="12" sm="12" md="12" lg="" className={[style.col, "d-none d-lg-block"].join(' ')}> { timeColDivs } </Col> ;
  }

  setMinMaxTime = () => {
    this.time = {
      minTime: undefined,
      maxTime: undefined,
    }
    this.martialArts.forEach((martialArt, i) => {
      martialArt.time && martialArt.time.forEach((day, j) => {
        (this.time.minTime === undefined || this.time.minTime > day.start) && (this.time.minTime = parseInt(day.start.toString().charAt(0) + day.start.toString().charAt(1) + "00"));
        (this.time.maxTime === undefined || this.time.maxTime < day.end) && (this.time.maxTime = day.end);
      });
    });
  }

  createTable = () => {
    this.filter();
    this.setMinMaxTime();
    let cols = [];
    cols.push(this.createTimeCol());
    cols.push(this.createDayCols());

    return cols;
  }

  createButtons = () => {
    let buttons = [<Button key="Alle" className={style.button} onClick={() => {this.setState({filter: null})}} variant="primary"> Alle </Button>];
    data.kategories.forEach((kategorie, i) => {
      let activ = this.state.filter === kategorie;
      let martialArt = data.martialArts.filter(ma => ma.kategorie === kategorie)[0]
      buttons.push(<Button 
        key={kategorie}
        className={[style.button, activ ? style.activ : ''].join(' ')} 
        style={!activ ? {
          backgroundColor: martialArt.style.backgroundColor,
          color: "rgb(0,0,0)"
        } : {
          backgroundColor: "rgb(255,255,255)",
          color: martialArt.style.backgroundColor
        }}
        onClick={() => {
          this.setState({filter: kategorie})
          console.log(kategorie)
        }} 
        variant="primary"
      > 
          { kategorie } 
      </Button>)
    });
    return <div className={style.filterDiv}> { buttons } </div>
  }

  render() {
    return (
      <Container>
        <Row className={style.row}>
          { this.createButtons() }
        </Row>
        <Row className={[style.row, 'plan'].join(' ')}>          
          {this.createTable()}
        </Row>
        <Row className={style.row}>
          <Button 
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: "25px"
            }} 
            className={style.button} 
            onClick={this.downloadTimeTable} 
            variant="primary"
          > 
            Download 
          </Button>
        </Row>
      </Container>
    )
  }
}

export default TimeTable;