import React from 'react';
import { Link } from 'react-router-dom';
import style from './footer.module.scss';

import imgFb from '../../../assets/images/fb.jpeg';
import imgInsta from '../../../assets/images/insta.png';
import imgTwitter from '../../../assets/images/twitter.svg';
import imgTumblr from '../../../assets/images/tumblr.svg';
import imgYt from '../../../assets/images/yt.svg';
import imgMaps from '../../../assets/images/maps.png';
import imgDiscord from '../../../assets/images/discord.svg';
//https://discord.gg/THVWXhrAWD
class Footer extends React.Component {
  render () {
    return (
        <div id="Footer" {...this.props}>
          <Link className={style.link} to="/impressum">Impressum</Link>
          <Link className={style.link} to="/datenschutz">Datenschutz</Link>

          <div className={style.footerLinks}>
            <a className={style.link} title="Discord" href="https://discord.gg/THVWXhrAWD" target="_blank" rel="noopener noreferrer">
              <img className={style.icon} src={imgDiscord} alt="Discord" />
            </a>
            <a className={style.link} title="Facebook" href="https://www.facebook.com/AttitudeSportsNB/" target="_blank" rel="noopener noreferrer">
              <img className={style.icon} src={imgFb} alt="Facebook" />
            </a>
            <a className={style.link} title="Instagram" href="https://www.instagram.com/attitudesportsnb/" target="_blank" rel="noopener noreferrer">
              <img className={style.icon} src={imgInsta} alt="Instagram" />
            </a>
            <a className={style.link} title="Twitter" href="https://twitter.com/AttitudeNb" target="_blank" rel="noopener noreferrer">
              <img className={style.icon} src={imgTwitter} alt="Twitter" />
            </a>
            <a className={style.link} title="Tumblr" href="https://www.tumblr.com/blog/attitudesportsnb" target="_blank" rel="noopener noreferrer">
              <img className={style.icon} src={imgTumblr} alt="Tumblr" />
            </a>            
            <a className={style.link} title="YouTube" href="https://www.youtube.com/channel/UCQczhCmq1oqKXnnuTX1PzsA" target="_blank" rel="noopener noreferrer">
              <img className={style.icon} src={imgYt} alt="YouTube" />
            </a>
            <a className={style.link} title="Google Maps" href="https://www.google.de/maps/place/Attitude+Sports+NB+-+Martial+Arts+Academy/@53.5618582,13.2701508,17z/data=!3m1!4b1!4m5!3m4!1s0x47abc3bb91686e5f:0xe2feee429604b602!8m2!3d53.561855!4d13.2723395" target="_blank" rel="noopener noreferrer">
              <img className={style.icon} src={imgMaps} alt="Google Maps" />
            </a>
          </div>          
        </div>
    );
  };
}

export default Footer;