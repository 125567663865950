import React from "react";
import { Container, Row, Col, Button, CustomInput } from "reactstrap";
import Layout from "../../components/layout";

import style from "./preise.module.scss";

class Preise extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      month: false,
    }
  }

  render() {
    return (
      <Layout pagename="Preise">
        <Container className={style.container}>
          <Row className={style.row}>
            <Col md="12">
              <div>
                Schau dir die Preisübersicht der von uns angebotenen Kampfsportpakete an. 
                <br/>
                Falls du einfach mal in der Gegend bist und nur kurze Zeit mit uns trainieren möchtest, kannst du einen <b>Kurspass</b> kaufen.
                <br/>
                Ein kostenloses Probetraining ist jederzeit möglich.
              </div>             
            </Col>
          </Row>

          <Row className={style.row}>
            <Col md="12">
              <div>
                Du trainierst bereits in einem Kampfsport- oder Kampfkustverein und möchtest deinen persönlichen Stil erweitern? Dann sprich uns für ein <b>individuelles Preisangebot</b> direkt an!
              </div>             
            </Col>
          </Row>

          <Row className={style.row}>
            <Col md="12">
              <div>
                <b>Bildung und Teilhabe</b> - Wenn du über eine Bildungskarte verfügst und die Nummer bei der Anmeldung mit angiebst, ziehen wir den entsprechenden Teil (15€) vom Monatsbeitrag ab und buchen diesen stattdessen von der Bildungskarte ab.
              </div>             
            </Col>
          </Row>

          <Row className={style.row}>
            <Col md="12">
              <div className={style.inline}>                
                <span>Jahresvertrag</span>    
                <CustomInput
                  className={style.inline}
                  type="switch" 
                  id="yearSwitch"
                  value={this.state.month}
                  onClick={() => this.setState({month: !this.state.month})}
                />
                <span>Monatlich kündbar</span>       
              </div>           
            </Col>
          </Row>

          {/* #################### Ab Oktober #################### */}

          <Row className={style.row2}>
            <Col lg="4" md="6" sm="12" className={style.priceCol}>
              <div className={style.priceDiv}>
                <span className={style.price}>Gratis</span>
              </div>
              <div className={style.produktName}>
                <h2>Probetraining</h2>
              </div>
              <div className={style.produktName}>
                Ein unverbindliches und kostenloses Probetraining
              </div>
              <div className={style.produktName}>
                Nur einmalig buchbar
              </div>
              <div className={style.produktName}>
                <a href={`https://attitudesports.gymdesk.com/signup/v/DLO0v?membership=13640&type=1`} target="_blank" rel="noopener noreferrer">
                  <Button>
                    Auswählen
                  </Button>
                </a>
              </div>              
            </Col>

            <Col lg="4" md="6" sm="12" className={style.priceCol}>
              <div className={style.priceDiv}>
                {this.state.month ? <span className={style.price}>72€</span> : <span className={style.price}>60€</span>}                
                <span className={style.month}>/MO</span>
              </div>
              <div className={style.produktName}>
                <h2>Volle Mitgliedschaft</h2>
              </div>
              <div className={style.produktName}>
                {this.state.month ? 
                  <span>1 Monat Vertragslaufzeit</span> : 
                  <span>1 Jahr Vertragslaufzeit. Danach monatlich kündbar.</span>
                }  
              </div>
              <div className={style.produktName}>
                Teilnahme an allen Kampfsportkursen
              </div>
              <div className={style.produktName}>
                Freie Nutzung des Außenbereichs
              </div>
              <div className={style.produktName}>
                <a href={`https://attitudesports.gymdesk.com/signup/v/A13wX?membership=${this.state.month ? '47797' : '47243'}&type=2`} target="_blank" rel="noopener noreferrer">
                  <Button>
                    Auswählen
                  </Button>
                </a>
              </div>              
            </Col>

            <Col lg="4" md="6" sm="12" className={style.priceCol}>
              <div className={style.priceDiv}>
                {this.state.month ? <span className={style.price}>48€</span> : <span className={style.price}>40€</span>}   
                <span className={style.month}>/MO</span>
              </div>
              <div className={style.produktName}>
                <h2>Reduzierte Mitgliedschaft</h2>
              </div>
              <div className={style.produktName}>
                Für Schüler, Studenten, Azubis, Rentner, Arbeitssuchende, ...
              </div>
              <div className={style.produktName}>
                {this.state.month ? 
                  <span>1 Monat Vertragslaufzeit</span> : 
                  <span>1 Jahr Vertragslaufzeit. Danach monatlich kündbar.</span>
                }  
              </div>
              <div className={style.produktName}>
                Teilnahme an allen Kampfsportkursen
              </div>
              <div className={style.produktName}>
                Freie Nutzung des Außenbereichs
              </div>
              <div className={style.produktName}>
                <a href={`https://attitudesports.gymdesk.com/signup/v/A13wX?membership=${this.state.month ? '47798' : '47244'}&type=2`} target="_blank" rel="noopener noreferrer">
                  <Button>
                    Auswählen
                  </Button>
                </a>
              </div>  
            </Col>

            <Col lg="4" md="6" sm="12" className={style.priceCol}>
              <div className={style.priceDiv}>
                <span className={style.price}>120€</span>
              </div>
              <div className={style.produktName}>
                <h2>10er Karte</h2>
              </div>
              <div className={style.produktName}>
                1 Jahr Gültigkeit.
              </div>
              <div className={style.produktName}>
                Teilnahme an bis zu 10 Kampfsportkursen
              </div>
              <div className={style.produktName}>
                <a href="https://attitudesports.gymdesk.com/signup/v/A13wX?membership=13594&type=2" target="_blank" rel="noopener noreferrer">
                  <Button>
                    Auswählen
                  </Button>
                </a>
              </div>              
            </Col>

            <Col lg="4" md="6" sm="12" className={style.priceCol}>
              <div className={style.priceDiv}>
                <span className={style.price}>20€</span>
              </div>
              <div className={style.produktName}>
                <h2>Tageskarte</h2>
              </div>
              <div className={style.produktName}>
                Teilnahme an allen Kampfsportkursen an einem Tag
              </div>
              <div className={style.produktName}>
                <a href="https://attitudesports.gymdesk.com/signup/v/A13wX?membership=61719&type=2" target="_blank" rel="noopener noreferrer">
                  <Button>
                    Auswählen
                  </Button>
                </a>
              </div>              
            </Col>

            <Col lg="4" md="6" sm="12" className={style.priceCol}>
              <div className={style.priceDiv}>
                <span className={style.price}>15€</span>
              </div>
              <div className={style.produktName}>
                <h2>Kurspass</h2>
              </div>
              <div className={style.produktName}>
                Teilnahme an einem Kampfsportkurs
              </div>
              <div className={style.produktName}>
                <a href="https://attitudesports.gymdesk.com/signup/v/A13wX?membership=24522&type=2" target="_blank" rel="noopener noreferrer">
                  <Button>
                    Auswählen
                  </Button>
                </a>
              </div>              
            </Col>
          </Row>

          <Row className={style.row}>
            <Col md="12">
              <div>
                <b>Partnerprogramm!</b> Jede weitere Person die du in deinen Vertrag mit aufnimmst, bekommt <b>25% Rabatt</b>.
              </div>             
            </Col>
          </Row>

          <Row className={style.row}>
            <Col md="12">
              <div>
                Du kannst dir die Kurse nicht leisten? Sprich uns an und wir werden uns um eine passende Lösung für dich bemühen!
              </div>             
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default Preise;
