import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import style from './header.module.scss';
import Logo from '../../../assets/images/Logo.png';
// import Zr from '../../../assets/images/zr.png';
import data from '../../../assets/data.js';

class NavBar extends React.Component {
  constructor(props) {
    super(props)

    this.refHeader = React.createRef();
    this.state = {
      isOpen: false,
      height: 0,
      pagename: ''
    }
  }

  toggle = () => this.setState({isOpen: !this.state.isOpen});

  componentDidMount () {    
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  scrollAnker = (e) => {
    let scrollY = e.screenY;
    window.scrollTo(0, scrollY); 
  }

  getSportsNavs = () => {
    let DropdownItems = [];
    data.martialArts.forEach((martialArt, i) => {
      if (martialArt.hideAsCourese) {return}
      DropdownItems.push(
        <DropdownItem key={martialArt.anker} className={style.dropdownItem}>
          <NavLink href={"/sports#" + martialArt.anker}>{ martialArt.name } </NavLink>
        </DropdownItem>
      )
    });
    return DropdownItems;
  }

  getHeader = () => {
    return (
      <div id="Header" {...this.props} ref={this.refHeader}>
        <Navbar id="navbar" color="dark" dark={true} expand="lg" className={style.navBar}>
          <NavbarBrand className={["d-inline d-lg-none", style.brandMobile].join(' ')} href="/">            
            <img src={Logo} alt="Logo" className={style.imgLogo} />
            {/* <img src={Zr} alt="Logo" className={style.imgLogo} /> */}
          </NavbarBrand>

          <NavbarToggler onClick={this.toggle} className={style.navToggler} />

          <Collapse isOpen={this.state.isOpen} navbar className={[style.collapse, "float-lg-right float-md-right"].join(' ')}>
            <Nav className={style.center} navbar>

              <NavbarBrand href="/" className={["d-none d-lg-inline", style.brand].join(' ')}>
                <img src={Logo} alt="Logo" className={style.imgLogo} />
                {/* <img src={Zr} alt="Logo" className={style.imgLogo} /> */}
              </NavbarBrand>

              <UncontrolledDropdown nav inNavbar className={style.navItem} color="dark">
                <DropdownToggle nav caret>
                  Unser Angebot
                </DropdownToggle>
                <DropdownMenu right className={style.dropdown}>
                  { this.getSportsNavs() }
                </DropdownMenu>
              </UncontrolledDropdown>

              {/* <div className={style.line}></div>              

              <NavItem className={style.navItem}>
                <NavLink href="/timetable">Trainingsplan</NavLink>
              </NavItem> */}

              <div className={style.line}></div>              

              <NavItem className={style.navItem}>
                <NavLink href="/events">Kursplan & Events</NavLink>
              </NavItem>

              <div className={style.line}></div>          

              <NavItem className={style.navItem}>
                <NavLink href="/trainer">Team & Partner</NavLink>
              </NavItem>

              <div className={style.line}></div>

              <NavItem className={style.navItem}>
                <NavLink href="/preise">
                  Preise 
                </NavLink>
              </NavItem>

              <div className={style.line}></div>

              <NavItem className={style.navItem}>
                <NavLink href="/projekt">
                  Dein Projekt 
                </NavLink>
              </NavItem>

              <div className={style.line}></div>

              <NavItem className={style.navItem}>
                <NavLink href="/contact">Kontakt & Medien</NavLink>
              </NavItem>    

              <div className={style.line}></div> 

              <NavItem className={style.navItem}>
                <NavLink href="https://attitudesports.maonrails.com/login" target="_blank" rel="noopener noreferrer">
                    Member Login 
                </NavLink>
              </NavItem>

              <div className={style.line}></div> 

              <NavItem className={[style.navItem, style.navItemInvert].join(' ')}>
                <NavLink href={`https://attitudesports.gymdesk.com/signup/v/DLO0v?membership=13640&type=1`} target="_blank" rel="noopener noreferrer">
                    Probetraining
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    )
  }

  render () {
    return(
      this.getHeader()
    )
  };
}

export default NavBar;