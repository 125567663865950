import React from 'react';
import PagePath from './header/pagepath';
import Header from './header';
import Footer from './footer';
import styles from './layout.module.scss';
import ReactGA from 'react-ga';

import CookieConsent from "react-cookie-consent";

class Layout extends React.Component {  
  componentDidMount() {
    ReactGA.initialize('UA-126826771-1');     
    ReactGA.pageview(this.props.pagename);
  }

  render () {
    return (
      <div style={this.props.style} className={styles.layoutContainer}>
        <div className={styles.bg}>

        </div>

        <Header className={styles.header} pagename={this.props.pagename} />       

        <div style={this.props.contentStyle} className={styles.content}>
          { this.props.pagename ? <PagePath pagename={this.props.pagename} /> : '' }
          { this.props.children }
          <Footer className={styles.footer}/>
        </div>

        <div class="maonrails-form" attr-ref="leeJM" attr-gym="6Vr3Y"></div>

        <CookieConsent
          location="bottom"
          buttonText="OK"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Diese Website nutzt Cookies. Mit der Nutzung dieser Seite erkläre Sie sich mit den <a href="/datenschutz">Datenschutzrichtlienien</a> einverstanden.{" "}
        </CookieConsent>
      </div>
    );
  }
}

export default Layout;