import React from 'react';
import YouTube from 'react-youtube';

export default { 
    "trainer": [
        {
            "name": "Sunny",
            "anker": "sunny",
            "image": "sunny.png",
            "trainerFor": [
                "mma", "bjj", "wettkampf", "allkampf", "capoeira", "ringen", "boxing"
            ],
            "experience": [
                {
                    "martialArt": "Ju-Jutsu",
                    "duration": "4",
                    "rank": "4. Kyu (Orange)"
                },
                {
                    "martialArt": "Judo",
                    "duration": "4",
                    "rank": "5. Kyu (Orange)"
                },
                {
                    "martialArt": "Muay Thai",
                    "duration": "2"
                },
                {
                    "martialArt": "BJJ / Grappling",
                    "since": "2015",
                    "rank": "BJJ Purple Belt"
                },
                {
                    "martialArt": "MMA",
                    "since": "2015"
                }
            ],
            "description": "Headcoach Sunny hat mit ca. 12 Jahren angefangen Judo zu trainieren. Mit 14 kam noch Ju-Jutsu dazu. Im Laufe des Trainings entwickelte sich ein immer größeres Interesse für andere Kampfkünste. Er spezialisierte sich im Laufe der Zeit besonders in den Bereichen MMA und Grappling (später BJJ). Im Januar 2015 gründete er die Kampfsportschule \"Attitude Sports NB - Martial Arts Academy \". Früher noch als Combat Punx oder Tick Fighting bekannt, entwickelte sich die Academy zu einer der größten Kampfsportschulen in Neubrandenburg. Seit der Eröffnung der Academy fährt er regelmäßig zu Seminaren und Wettkämpfen und versucht seine Kämpfer_innen in allen Lagen zu unterstützen.",
            "stats": [
                {
                    "name": "Allkampf",
                    "win": 12,
                    "loss": 5,
                    "draw": 0
                },
                {
                    "name": "BJJ/Grappling",
                    "win": 3,
                    "loss": 7,
                    "draw": 0
                },
                {
                    "name": "Jiu Jitsu (Vollkontakt)",
                    "win": 1,
                    "loss": 0,
                    "draw": 0
                },
                {
                    "name": "MMA-Light",
                    "win": 0,
                    "loss": 4,
                    "draw": 0
                },
                {
                    "name": "MMA",
                    "win": 0,
                    "loss": 1,
                    "draw": 0
                },
            ],
            "awards": [
                {
                    "name": "Allkampf Meisterschaften",
                    "rank": "3x Gold, 3x Silber"
                },
                {
                    "name": "Vollkontakt Jiu Jitsu",
                    "rank": "1x Gold"
                }
            ]
        },        
        // {
        //     "name": "Benni",
        //     "anker": "benni",
        //     "image": "benni.jpg",
        //     "trainerFor": [
        //         "rr"
        //     ],
        //     "experience": [
        //         {
        //             "martialArt": "MMA",
        //             "since": "2016"
        //         },
        //         {
        //             "martialArt": "BJJ / Grappling",
        //             "since": "2016"
        //         }
        //     ],
        //     "description": "Mehr Informationen folgen",
        //     "stats": [
        //         {
        //             "name": "Allkampf",
        //             "win": 0,
        //             "loss": 2,
        //             "draw": 0
        //         },
        //         {
        //             "name": "BJJ/Grappling",
        //             "win": 2,
        //             "loss": 0,
        //             "draw": 0
        //         }
        //     ],
        //     "certs": [
        //         {
        //             "name": "Ausgebildeter Erzieher"
        //         }
        //     ],
        //     "awards": [

        //     ]
        // },
        {
            "name": "Torsten",
            "anker": "torsten",
            "image": "torsten.jpg",
            "trainerFor": [
                "boxing"
            ],
            "experience": [
                {
                    "martialArt": "Boxen",
                    "duration": "5",
                    "rank": "Über 200 Kämpfe"
                },
                {
                    "martialArt": "MMA",
                    "since": "2020"
                },
                {
                    "martialArt": "BJJ / Grappling",
                    "since": "2020"
                }
            ],
            "description": "Mehr Informationen folgen",
            "stats": [
            ],
            "awards": [

            ]
        },
        {
            "name": "Florian",
            "anker": "florian",
            "image": "flo.jpeg",
            "trainerFor": [
                "striking"
            ],
            "experience": [
                {
                    "martialArt": "Thaiboxen / Striking",
                    "since": "2020"
                },
                {
                    "martialArt": "Karate",
                    "duration": "10"
                },
                {
                    "martialArt": "Kickboxen",
                    "duration": "2"
                }
            ],
            "description": "Mehr Informationen folgen",
            "stats": [
                {
                    "name": "Striking-MMA (Light)",
                    "win": 3,
                    "loss": 2,
                    "draw": 0
                },
                {
                    "name": "Striking-MMA",
                    "win": 0,
                    "loss": 1,
                    "draw": 0
                },
                {
                    "name": "K1",
                    "win": 0,
                    "loss": 1,
                    "draw": 0
                }
            ],
            "awards": [

            ]
        },
        {
            "name": "Muhab Gammoh",
            "anker": "muhab",
            "image": "muhab.jpg",
            "trainerFor": [
                "taekwondo", "striking"
            ],
            "experience": [
                {
                    "martialArt": "BJJ",
                    "since": "2023"
                },
                {
                    "martialArt": "MMA",
                    "since": "2023"
                },
                {
                    "martialArt": "Taekwondo",
                    "duration": "6",
                    "rank": "1. DAN (Schwarzgurt)"
                }
            ],
            "description": "Mehr Informationen folgen",
            "stats": [

            ],
            "certs": [
            ],
            "awards": [

            ]
        },
        {
            "name": "Lars",
            "anker": "lars",
            "image": "lars.jpg",
            "trainerFor": [
                "meditativerkampf", "meditation"
            ],
            "experience": [
                {
                    "martialArt": "Judo",
                    "duration": "1"
                },
                {
                    "martialArt": "Shaolin Kung Fu",
                    "duration": "1"
                },
                {
                    "martialArt": "Qi Gung Chuan",
                    "duration": "1"
                },
                {
                    "martialArt": "Ving Tsun Kung Fu",
                    "since": "2007"
                }
            ],
            "description": "Mehr Informationen folgen",
            "stats": [

            ],
            "certs": [
                {
                    "name": "Bachelor Soziale Arbeit"                    
                },
                {
                    "name": "Trainerschein C-Lizenz (2013-2017)"                    
                },
            ],
            "awards": [

            ]
        },
        {
            "name": "Ahmad",
            "anker": "ahmad",
            "image": "ahmad.jpg",
            "trainerFor": [
                "mma", "bjj", "allkampf", "ringen"
            ],
            "experience": [
                {
                    "martialArt": "Muay Thai",
                    "duration": "1"
                },
                {
                    "martialArt": "BJJ / Grappling",
                    "since": "2020",
                    "rank": "BJJ Blue Belt"
                },
                {
                    "martialArt": "MMA",
                    "since": "2020"
                }
            ],
            "description": "",
            "stats": [
                {
                    "name": "Allkampf",
                    "win": 5,
                    "loss": 2,
                    "draw": 0
                },
                {
                    "name": "BJJ/Grappling",
                    "win": 5,
                    "loss": 0,
                    "draw": 0
                },
                {
                    "name": "MMA-Light",
                    "win": 4,
                    "loss": 1,
                    "draw": 0
                },
            ],
            "awards": [
                {
                    "name": "Allkampf Meisterschaften",
                    "rank": "1x Gold"
                },
            ]
        },    
        // {
        //     "name": "Sven",
        //     "anker": "sven",
        //     "image": "sven.jpg",
        //     "trainerFor": [
        //         "bjj"
        //     ],
        //     "experience": [
        //         {
        //             "martialArt": "BJJ",
        //             "since": "2018",
        //             "rank": "BJJ Purple Belt"
        //         }
        //     ],
        //     "description": "Mehr Informationen folgen",
        //     "stats": [
        //         {
        //             "name": "BJJ/Grappling",
        //             "win": 1,
        //             "loss": 1,
        //             "draw": 0
        //         }
        //     ],
        //     "certs": [
        //     ],
        //     "awards": [

        //     ]
        // },        
    ],
    "martialArts": [
        {
            "name": "Mixed Martial Arts",
            "kategorie": "Mixed",
            "age": "ab 14 Jahren",
            "anker": "mma",
            "shortDescription": "The supreme discipline of martial arts",
            "description": 
                <div>
                    MMA ist eine der am schnellsten wachsenden Sportarten weltweit und kombiniert Techniken aus verschiedenen Kampfsportarten wie Boxen, Muay Thai, Jiu-Jitsu, Ringen und Karate. Durch diese Vielfalt ist MMA eine der vollständigsten Kampfsportarten und ermöglicht es den Athleten, ihre Fähigkeiten in verschiedenen Kampfsituationen zu testen.
                    <br/><br/>
                    Ein wesentliches Merkmal von MMA ist die Vielseitigkeit der Techniken, die es den Athleten ermöglicht, sich sowohl im Stehen als auch auf dem Boden zu verteidigen. Beim Standkampf werden Techniken wie Schläge, Tritte, Knie und Ellenbogen eingesetzt, während im Bodenkampf Techniken wie Hebel, Würgegriffe und Kontrolltechniken angewendet werden. Durch das Erlernen und Anwenden einer Vielzahl von Techniken können Athleten ihren eigenen Stil entwickeln und anpassen, um sich an verschiedene Gegner und Kampfsituationen anzupassen.
                    <br/><br/>
                    Neben der körperlichen Herausforderung bietet MMA auch eine effektive Methode der Selbstverteidigung. Das Training hilft Athleten, ihre Reaktionsfähigkeit und Körperbeherrschung zu verbessern und sich in kritischen Situationen zu verteidigen. Durch das Erlernen von Techniken wie Würfen, Abwehr von Takedowns und der Verteidigung von Würge- und Hebelgriffen können Athleten sich in einer Vielzahl von Selbstverteidigungssituationen schützen.
                    <br/><br/>
                    MMA hat auch eine starke Wettkampfkomponente, die Athleten die Möglichkeit gibt, sich in einem sicheren und kontrollierten Umfeld zu messen. Im Wettkampf werden die Athleten nach Gewichtsklassen eingeteilt und müssen sich an strenge Regeln und Sicherheitsvorschriften halten. Die Kämpfer tragen Schutzausrüstung und es gibt qualifizierte Schiedsrichter, die die Einhaltung der Regeln überwachen.
                    <br/><br/>
                    Insgesamt bietet MMA eine einzigartige Kombination aus körperlicher Herausforderung, mentaler Stärke und Selbstverteidigungsfähigkeiten. Es ist eine Sportart, die Athleten dabei unterstützt, ihre Fitness zu verbessern, ihre Techniken zu perfektionieren und ihre geistige Stärke zu fördern. Ob Sie nun nach einer Herausforderung suchen oder einfach nur Ihre Fähigkeiten verbessern möchten, MMA ist eine großartige Möglichkeit, um Ihre Grenzen zu testen und Ihre Kampfsportfähigkeiten zu erweitern.
                </div>,
            "image": "mma.png",
            "style": {
                "backgroundColor": "rgba(120, 120, 120, 0.5)",
                "color": "rgba(0, 0, 0)"
            },
            "time": [
                {
                    "day": "mo",
                    "start": 1800,
                    "end": 1900,
                    "subText": "4Fghtr"
                },
                {
                    "day": "mo",
                    "start": 1900,
                    "end": 1950,
                    "subText": "Sparring"
                },
                {
                    "day": "di",
                    "start": 1950,
                    "end": 2050,
                    "subText": '4Fghtr'
                },
                {
                    "day": "di",
                    "start": 2050,
                    "end": 2100,
                    "subText": "Sparring"
                },
            ]
        },
        {
            "name": "Striking",
            "kategorie": "Striking",
            "age": "ab 14 Jahren",
            "anker": "striking",
            "shortDescription": "Viel mehr als nur Kickboxen!",
            "description": 
                <div>
                    Striking ist eine Kampfkunst, die sich auf Schläge, Tritte, Ellenbogen und Knie konzentriert. Es ist eine dynamische und kraftvolle Kampfsportart, die Dich körperlich und geistig herausfordert.
                    <br/><br/>
                    Unsere Trainingsmethode ist darauf ausgerichtet, dass Du Schritt für Schritt die verschiedenen Techniken des Striking erlernst und Deine Fähigkeiten verbessern kannst. Du lernst, wie Du Deine Schläge und Tritte präzise ausführst, um Deine Gegner zu treffen und ihre Angriffe abzuwehren.
                    <br/><br/>
                    Ein wichtiger Teil des Striking ist die Wurfabwehr. Du lernst, wie Du Dich gegen Würfe und Takedowns verteidigen kannst und wie Du Deine Gegner im Standkampf behältst. Dabei wirst Du Deine Reflexe, Deine Körperbeherrschung und Dein Gleichgewicht verbessern.
                    <br/><br/>
                    Unser Schwerpunkt liegt darauf, dass Du die verschiedenen Techniken des Striking beherrschst, um Dich in verschiedenen Situationen verteidigen zu können. Ob im Alltag oder in einer potentiell gefährlichen Situation, Du wirst in der Lage sein, Dich zu schützen.
                    <br/><br/>
                    Das Training im Striking ist nicht nur körperlich anspruchsvoll, sondern auch geistig herausfordernd. Du lernst, wie Du Dich konzentrierst, fokussierst und Deine Emotionen kontrollierst, um im Kampf klar zu denken und schnell zu reagieren.
                </div>,
            "image": "sbox.jpg",
            "style": {
                "backgroundColor": "rgba(255, 0, 0, 0.5)",
                "color": "rgba(0, 0, 0)"
            },
            "time": [
                {
                    "day": "fr",
                    "start": 1800,
                    "end": 1900,
                    "subText": "4Fghtr"
                },
                {
                    "day": "fr",
                    "start": 1900,
                    "end": 1950,
                    "subText": "Sparring"
                }
            ]
        },
        {
            "name": "Brazilian Jiu Jitsu",
            "kategorie": "Grappling",
            "age": "ab 14 Jahren",
            "anker": "bjj",
            "shortDescription": "The art of humen chess",
            "description": 
                <div>
                    BJJ ist eine Kampfsportart, die sich auf den Bodenkampf konzentriert. Mit seinen Wurzeln im Judo und im traditionellen Jiu-Jitsu, nutzt BJJ Hebel- und Würgetechniken, um den Gegner auf dem Boden zu besiegen. Im Gegensatz zu anderen Kampfsportarten legt BJJ den Fokus auf die Verteidigung und die Arbeit aus der Rückenlage heraus, was es zu einer der effektivsten Kampfkünste im Bodenkampf macht.
                    <br/><br/>
                    Ein weiterer wichtiger Aspekt von BJJ ist die Tatsache, dass es eine Sportart ist, die sowohl körperlich als auch geistig herausfordernd ist. Es erfordert viel Kraft, Ausdauer und Flexibilität, um die verschiedenen Techniken auszuführen und sich in Wettkämpfen zu bewähren. Gleichzeitig fördert es jedoch auch die Konzentration, die Geduld und die Selbstbeherrschung, die in jeder Situation von entscheidender Bedeutung sind.
                    <br/><br/>
                    Eine der einzigartigen Eigenschaften von BJJ ist die Fähigkeit, die Rückenlage zu einer dominanten Position zu machen. Hierbei geht es nicht nur darum, sich vor Angriffen zu schützen, sondern auch darum, den Gegner zu kontrollieren und seine Bewegungen zu beschränken. Diese Fähigkeit ist besonders in der Selbstverteidigung von unschätzbarem Wert und macht BJJ zu einer effektiven Methode, um sich in kritischen Situationen zu verteidigen.
                    <br/><br/>
                    In unserem Training lernst Du die verschiedenen Techniken und Strategien, die notwendig sind, um im BJJ erfolgreich zu sein. Wir legen großen Wert auf die Verbesserung Deiner Techniken und Deiner Körperbeherrschung, damit Du Dich selbstbewusst und effektiv in jeder Situation verteidigen kannst.
                </div>,
            "image": "bjj.png",
            "style": {
                "backgroundColor": "rgba(0, 0, 255, 0.5)",
                "color": "rgba(0, 0, 0)"
            },
            "time": [
                {
                    "day": "mo",
                    "start": 1650,
                    "end": 1750,
                    "subText": "4Fghtr"
                },
                {
                    "day": "mo",
                    "start": 1750,
                    "end": 1800,
                    "subText": "Sparring"
                },
                {
                    "day": "do",
                    "start": 1950,
                    "end": 2050,
                    "subText": "4Fghtr | Gi"
                },
                {
                    "day": "do",
                    "start": 2050,
                    "end": 2100,
                    "subText": "Sparring"
                },
                
            ]
        },       
        {
            "name": "Taekwondo",
            "kategorie": "Striking",
            "anker": "taekwondo",
            "shortDescription": "Die Kunst der Kicks",
            "description": 
                <div>
                    Taekwondo, eine dynamische koreanische Kampfkunst, bekannt für ihre schnellen und kraftvollen Techniken, wird bei uns mit einem klaren Ziel trainiert: Anwendbarkeit im Kampf und Selbstverteidigung.
                    <br/><br/>
                    Unsere Taekwondo-Kurse unterscheiden sich von traditionellen Ansätzen, indem sie weniger auf ästhetische Formen, sondern vielmehr auf die Effektivität im Kampf fokussiert sind. Bei uns steht die praktische Anwendbarkeit der erlernten Techniken im Mittelpunkt.
                    <br/><br/>
                    Im Gegensatz zur klassischen Ausführung von Taekwondo, die oft auf traditionelle Formen und Wettkämpfe ausgerichtet ist, legen wir einen starken Schwerpunkt auf Praxiserfahrung im Sparring und in realistischen Selbstverteidigungsszenarien.
                    <br/><br/>
                    Unser Training umfasst intensive Technikübungen, die darauf abzielen, die Fähigkeiten unserer Teilnehmer für reale Kampfsituationen zu schärfen. Techniken werden nicht nur erlernt, sondern auch aktiv im Sparring angewendet, um die Effektivität und Anwendbarkeit zu gewährleisten.
                    <br/><br/>
                    Unsere Variante von Taekwondo konzentriert sich darauf, die kraftvollen und schnellen Bewegungen dieser Kampfkunst zu vermitteln, ohne dabei die praktische Anwendbarkeit im Kampf zu vernachlässigen.
                    <br/><br/>
                    Willkommen zu einer Taekwondo-Erfahrung, die die traditionellen Elemente bewahrt, jedoch den Fokus auf praktische Anwendbarkeit und Effektivität im Kampfgeschehen legt.
                </div>,
            "age": "ab 14 Jahren",
            "image": "taekwondo.jpg",
            "style": {
                "backgroundColor": "rgba(255, 0, 0, 0.5)",
                "color": "rgba(0,0,0)"
            },
            "time": [
                {
                    "day": "mo",
                    "start": 1950,
                    "end": 2050,
                    "subText": "4Fghtr"
                },
                {
                    "day": "mo",
                    "start": 2050,
                    "end": 2100,
                    "subText": "Sparring"
                },
            ]
        },
        {
            "name": "Dirty Boxing",
            "kategorie": "Striking",
            "anker": "boxing",
            "shortDescription": "Dirty Boxing",
            "description": 
                <div>
                    Unser Boxkurs hebt sich ab, indem er eher dem Dirty Boxing ähnelt als dem klassischen Boxen. Dieses intensive Training konzentriert sich auf unkonventionelle Schlagtechniken und taktische Manöver, die speziell auf realistische Nahkampfsituationen ausgerichtet sind.
                    <br/><br/>
                    Im Gegensatz zum traditionellen Boxen legen wir im Dirty Boxing-Kurs den Fokus auf eine Vielfalt von Techniken, die über konventionelle Boxschläge hinausgehen. Wir vermitteln taktische Methoden, die effektiv in der Nähe und im Clinch angewendet werden können.
                    <br/><br/>
                    Unser Training betont die Anwendung von Ellbogen, Unterarmen und anderen Nahkampftechniken, um effektive Schläge und Verteidigungsmanöver in unmittelbarer Distanz zu trainieren.
                    <br/><br/>
                    Wir bieten ein umfassendes Programm, das Schlagtechniken, taktische Manöver und Konditionsübungen integriert, um die Kampffertigkeiten zu schärfen und die physische Belastbarkeit zu verbessern.
                    <br/><br/>
                    Der Dirty Boxing-Kurs ist eine Gelegenheit, unkonventionelle Kampftechniken zu erlernen, die effektiv in realen Nahkampfsituationen eingesetzt werden können und gleichzeitig eine herausfordernde körperliche und mentale Herausforderung darstellen.
                  </div>,
            "age": "ab 14 Jahren",
            "image": "boxing.png",
            "style": {
                "backgroundColor": "rgba(255, 0, 0, 0.5)",
                "color": "rgba(0,0,0)"
            },
            "time": [
                {
                    "day": "di",
                    "start": 1800,
                    "end": 1900,
                    "subText": "4Fghtr"
                },
                {
                    "day": "di",
                    "start": 1900,
                    "end": 1950,
                    "subText": "Sparring"
                },
            ]
        },
        {
            "name": "Combat Capoeira",
            "kategorie": "Striking",
            "anker": "capoeira",
            "shortDescription": "Fit & Fight",
            "description": 
                <div>
                    Capoeira, eine beeindruckende brasilianische Kampfkunst, vereint Bewegung, Musik und Kultur in einer einzigartigen Form. Unsere Interpretation von Capoeira hebt sich jedoch von der traditionellen Ausübung ab, da wir besonderen Wert auf Anwendbarkeit im Kampf und Wettkampf legen.
                    <br/><br/>
                    Unsere Capoeira-Kurse konzentrieren sich darauf, die erlernten Techniken nicht nur als Kunstform zu betrachten, sondern vielmehr darauf, wie sie in realen Kampfsituationen angewendet werden können. Im Gegensatz zum klassischen Ansatz liegt bei uns der Schwerpunkt weniger auf den kulturellen Aspekten, sondern vielmehr auf der praktischen Anwendbarkeit im Kampfgeschehen.
                    <br/><br/>
                    Wir kombinieren die traditionellen Elemente von Capoeira mit einem Schwerpunkt auf Effektivität und Anwendbarkeit im Wettkampf und in der Selbstverteidigung. Unser Training integriert intensive Technikübungen, die darauf abzielen, die Fähigkeiten unserer Teilnehmer für reale Kampfsituationen zu stärken.
                    <br/><br/>
                    Unsere Version von Capoeira hebt sich von der traditionellen Ausübung ab, da wir eine Variante anbieten, die gezielt auf die Effektivität in Kampfsituationen und Wettkämpfen ausgerichtet ist, ohne dabei die kulturellen Wurzeln zu vernachlässigen.
                    <br/><br/>
                    Unser Ziel ist es, eine Form von Capoeira zu vermitteln, die die kulturellen Aspekte bewahrt, aber gleichzeitig den Fokus auf praktische Anwendbarkeit und Effektivität im Kampf setzt. Willkommen zu einer einzigartigen Interpretation von Capoeira, die die Kunst mit Kampfsport verbindet.
                </div>,
            "age": "ab 14 Jahren",
            "image": "capoeira.png",
            "style": {
                "backgroundColor": "rgba(255, 0, 0, 0.5)",
                "color": "rgba(0,0,0)"
            },
            "time": [
                {
                    "day": "mi",
                    "start": 1950,
                    "end": 2050,
                    "subText": "4Fghtr"
                },
                {
                    "day": "mi",
                    "start": 2050,
                    "end": 2100,
                    "subText": "Sparring"
                },
            ]
        },
        {
            "name": "Allkampf",
            "kategorie": "Mixed",
            "anker": "allkampf",
            "shortDescription": "Traditional MMA",
            "description": 
                <div>
                    Der Allkampf, als Weiterentwicklung des traditionellen deutschen Ju Jutsu, ist eine ganzheitliche Kampfkunst, die die Vielfalt verschiedener Kampftechniken vereint und im Gi trainiert wird.
                    <br/><br/>
                    Unsere Interpretation des Allkampfes basiert auf dieser erprobten Tradition und zeichnet sich durch die Kombination von Techniken aus verschiedenen Kampfkünsten aus, während der Fokus auf taktischer Anwendbarkeit und einem ganzheitlichen Ansatz liegt.
                    <br/><br/>
                    Im Gegensatz zu spezifischen Einzelkampfstilen legen wir Wert darauf, die breite Palette der Kampftechniken im Allkampf zu integrieren und sie in realistischen, aber kontrollierten Situationen zu trainieren.
                    <br/><br/>
                    Unser Training betont die Beherrschung von Techniken aus diversen Kampfkünsten, die im Gi praktiziert werden, und fördert ihre Anwendbarkeit in Kampfsituationen, die eine umfassende Bandbreite an Fähigkeiten erfordern.
                    <br/><br/>
                    Unsere Variante des Allkampfes aus dem klassischen deutschen Ju Jutsu konzentriert sich darauf, ein vielfältiges Spektrum von Techniken zu vermitteln, die im Gi trainiert werden und eine ganzheitliche Entwicklung der Kampfkunst ermöglichen.
                    <br/><br/>
                    Willkommen zu einer Allkampf-Erfahrung, die die Vielseitigkeit verschiedener Kampftechniken im Gi betont und gleichzeitig die taktische Anwendbarkeit und Ganzheitlichkeit im Fokus hat.
                </div>,
            "age": "ab 14 Jahren",
            "image": "judo.png",
            "style": {
                "backgroundColor": "rgba(120, 120, 120, 0.5)",
                "color": "rgba(0,0,0)"
            },
            "time": [
                {
                    "day": "do",
                    "start": 1800,
                    "end": 1900,
                    "subText": "4Fghtr | Gi"
                },
                {
                    "day": "do",
                    "start": 1900,
                    "end": 1950,
                    "subText": "Sparring"
                },
            ]
        },
        {
            "name": "Catch Wrestling",
            "kategorie": "Grappling",
            "anker": "ringen",
            "shortDescription": "Hit someone with the planet",
            "description": 
                <div>
                    Ringen, eine der ältesten Kampfkünste der Welt, wird bei uns mit einem klaren Schwerpunkt auf praktischer Anwendbarkeit und Wettkampfvorbereitung gelehrt.
                    <br/><br/>
                    Unsere Interpretation des Ringens unterscheidet sich von traditionellen Ansätzen, indem wir die Techniken nicht nur als Sport betrachten, sondern sie auch auf Selbstverteidigungsszenarien und Wettkämpfe anwenden.
                    <br/><br/>
                    Im Gegensatz zum klassischen Verständnis des Ringens, das oft auf sportliche Wettkämpfe ausgerichtet ist, legen wir den Schwerpunkt darauf, die erlernten Techniken auch in realistischen Kampfsituationen effektiv einzusetzen.
                    <br/><br/>
                    Unser Training betont die praktische Anwendbarkeit der Ringtechniken im Sparring. Wir fördern die Fähigkeiten unserer Teilnehmer, indem wir Techniken nicht nur lehren, sondern auch aktiv im Ringkampf anwenden und verfeinern.
                    <br/><br/>
                    Unsere Variante des Ringens konzentriert sich darauf, die traditionellen Griffe und Würfe zu vermitteln, ohne dabei die praktische Anwendbarkeit im Kampf und Wettkampf zu vernachlässigen.
                    <br/><br/>
                    Willkommen zu einer Ringkampf-Erfahrung, die die traditionellen Elemente bewahrt, jedoch den Fokus auf praktische Anwendbarkeit und Effektivität im Kampfgeschehen legt.
                </div>,
            "age": "ab 14 Jahren",
            "image": "benni.jpg",
            "style": {
                "backgroundColor": "rgba(0, 0, 255, 0.5)",
                "color": "rgba(0,0,0)"
            },
            "time": [
                {
                    "day": "mi",
                    "start": 1800,
                    "end": 1900,
                    "subText": "4Fghtr"
                },
                {
                    "day": "mi",
                    "start": 1900,
                    "end": 1950,
                    "subText": "Sparring"
                },
                {
                    "day": "di",
                    "start": "0900",
                    "end": 1000,
                    "subText": "4Fghtr"
                },
                {
                    "day": "di",
                    "start": 1000,
                    "end": 1050,
                    "subText": "Sparring"
                },
                {
                    "day": "do",
                    "start": "0900",
                    "end": 1000,
                    "subText": "4Fghtr"
                },
                {
                    "day": "do",
                    "start": 1000,
                    "end": 1050,
                    "subText": "Sparring"
                },
            ]
        },
        // {
        //     "name": "Femi Martial Arts",
        //     "hideInTimetable": true,
        //     "anker": "fma",
        //     "shortDescription": "Personal Empowerment",
        //     "description": 
        //         <div>
        //             !!! Noch im Aufbau !!!
        //             <br/>
        //             Natürlich ist der Femi Martial Arts Kurs auch offen für alle Personen, die sich in einem Training mit vielen Männern nicht wohl fühlen. Egal ob aufgrund des Geschlechts, der sexuellen Orientierung oder anderer individueller Faktoren - wir heißen alle willkommen, die sich in einem sicheren und inklusiven Raum fit halten möchten.
        //             <br/><br/>
        //             Unser Ziel ist es, den Teilnehmerinnen das nötige Vertrauen und die notwendigen Fähigkeiten zu vermitteln, um sich in jeder Situation zu verteidigen. Der Kurs umfasst Techniken aus verschiedenen Kampfsportarten, darunter Boxen, Muay Thai, Brazilian Jiu Jitsu und vieles mehr. Unsere erfahrenen Trainerinnen zeigen Dir, wie Du Techniken wie Schläge, Tritte, Hebel- und Wurftechniken effektiv einsetzen kannst, um dich vor möglichen Angriffen zu schützen.
        //             <br/><br/>
        //             Wichtig ist dabei, dass wir auch im Femi Martial Arts Kurs Vollkontakt-Kampfsport betreiben. Wir legen dabei jedoch besonderen Wert auf die richtige Technik, den Schutz der Teilnehmenden und die gegenseitige Rücksichtnahme. In unseren Trainings geht es darum, Selbstverteidigungstechniken zu erlernen und körperliche Stärke aufzubauen - und das alles in einem feministischen Kontext.
        //             <br/><br/>
        //             Unser Ziel ist es, dass sich alle Teilnehmenden bei uns sicher und unterstützt fühlen. Wir möchten einen Raum schaffen, in dem Frauen und diverse Personen ohne Einschränkungen lernen und sich verbessern können, ohne sich Sorgen machen zu müssen, ob sie sich in einer unangenehmen Situation befinden.
        //         </div>,
        //     "image": "fma.jpg",
        //     "style": {
        //         "backgroundColor": "rgba(100, 25, 50, 0.5)",
        //         "color": "rgba(0,0,0)"
        //     },
        //     "time": [

        //     ]
        // },
        {
            "name": "Meditatives Kämpfen",
            "kategorie": "Meditativ",
            "anker": "meditativerkampf",
            "shortDescription": "Körperliche Präsenz und innere Ruhe im Meditativen Kämpfen",
            "description": 
                <div>
                    Meditatives Kämpfen bietet eine einzigartige Erfahrung, die sich von traditionellen Kampfkunsttrainings unterscheidet. Dieser Kurs konzentriert sich nicht auf das 4Fghtr Prinzip, sondern dient vielmehr dazu, ein tieferes Körpergefühl zu entwickeln und Bewegungsmuster auf eine meditative Art zu verinnerlichen.
                    <br/><br/>
                    Während herkömmliche Kampfkunsttrainings oft auf den Kampfsportaspekt fokussieren, liegt der Schwerpunkt dieses Kurses darauf, eine Verbindung zwischen Körper und Geist zu schaffen. Durch meditative Bewegungsabläufe und Achtsamkeitsübungen erkunden wir die Harmonie von Bewegung und innerer Ruhe.
                    <br/><br/>
                    Im "Meditativen Kämpfen" geht es nicht darum, Wettkampftechniken zu erlernen oder auf praktische Anwendbarkeit zu trainieren. Stattdessen bietet dieser Kurs Raum für Selbsterforschung, Selbstreflexion und Stressabbau durch sanfte, fließende Bewegungen.
                    <br/><br/>
                    Unsere Übungen konzentrieren sich auf das Erleben des gegenwärtigen Moments, das Bewusstsein für den eigenen Körper und die Förderung von Entspannung und innerem Gleichgewicht.
                    <br/><br/>
                    Dieser Kurs ist ideal für diejenigen, die eine Pause von der physischen Intensität des traditionellen Kampfsports suchen und stattdessen einen Weg zur Entspannung, Achtsamkeit und Selbstfindung durch die Verbindung von Bewegung und Meditation suchen.
                </div>,
            // "age": "ab 14 Jahren",
            "image": "gf.jpg",
            "style": {
                "backgroundColor": "rgba(255, 255, 0, 0.5)",
                "color": "rgba(0,0,0)"
            },
            "time": [
                {
                    "day": "di",
                    "start": 1650,
                    "end": 1800
                },
            ]
        },        
        {
            "name": "Meditation durch Bewegung",
            "kategorie": "Meditativ",
            "anker": "meditation",
            "shortDescription": "Mobilisierung, Dehnung und Kräftigung",
            "description": 
                <div>
                    Meditation durch Bewegung bietet eine einzigartige Erfahrung, die die Verbindung zwischen Körper und Geist durch gezielte Mobilisierungs-, Dehnungs- und Kräftigungsübungen fördert.
                    <br/><br/>
                    In diesem Kurs liegt der Fokus auf der Erforschung und Förderung der Körperbewegungen als Mittel zur Meditation und inneren Ausgeglichenheit. Wir nutzen gezielte Übungen zur Mobilisierung, um den Körper zu öffnen und die Beweglichkeit zu verbessern.
                    <br/><br/>
                    Durch gezielte Dehnungssequenzen schaffen wir Raum für Entspannung und verbessern gleichzeitig die Flexibilität und den Energiefluss im Körper. Die Dehnungsübungen werden behutsam durchgeführt, um eine sanfte, aber effektive Verbesserung der Beweglichkeit zu erreichen.
                    <br/><br/>
                    Des Weiteren integrieren wir Kräftigungsübungen, um die Muskeln zu stärken und die Körperhaltung zu verbessern. Dies trägt nicht nur zur physischen Fitness bei, sondern fördert auch das Körperbewusstsein und die Achtsamkeit für die eigenen Bewegungsmuster.
                    <br/><br/>
                    Dieser Kurs ist ideal für alle, die nach einer ganzheitlichen Erfahrung suchen, die Mobilisierung, Dehnung und Kräftigung durch Bewegung mit meditativen Aspekten verbindet. Er bietet Raum für Selbstreflexion, Entspannung und die Förderung eines harmonischen Zusammenspiels von Körper und Geist.
                </div>,
            // "age": "ab 14 Jahren",
            "image": "gf.jpg",
            "style": {
                "backgroundColor": "rgba(255, 255, 0, 0.5)",
                "color": "rgba(0,0,0)"
            },
            "time": [
                {
                    "day": "mo",
                    "start": 1550,
                    "end": 1650,
                    // "subText": "Gung Fu"
                },
            ]
        },
        {
            "name": "Dehnung",
            "kategorie": "Meditativ",
            "anker": "dehnung",
            "shortDescription": "Abdehnung der Muskulatur nach dem Training",
            "description": 
                <div>
                    Nach einem intensiven Training ist es wichtig, den Körper zu regenerieren und die Muskeln zu entspannen. Unsere 20-30-minütige Dehnungssession nach dem letzten Kurs bietet dir die perfekte Gelegenheit dazu. Unter der Anleitung unserer erfahrenen Trainer kannst du dich dehnen, um deine Flexibilität zu verbessern, Verletzungen vorzubeugen und die Spannung vom Training abzubauen. Mach mit und spüre, wie deine Beweglichkeit und dein Wohlbefinden sich mit jeder Dehnungseinheit steigern
                 </div>,
            // "age": "ab 14 Jahren",
            "image": "dehnung.png",
            "style": {
                "backgroundColor": "rgba(255, 255, 0, 0.5)",
                "color": "rgba(0,0,0)"
            },
            "time": [
                {
                    "day": "mo",
                    "start": 2100,
                    "end": 2150,
                    "hidetime": true
                },
                {
                    "day": "di",
                    "start": 2100,
                    "end": 2150,
                    "hidetime": true
                },
                {
                    "day": "mi",
                    "start": 2100,
                    "end": 2150,
                    "hidetime": true
                },
                {
                    "day": "do",
                    "start": 2100,
                    "end": 2150,
                    "hidetime": true
                },
                {
                    "day": "fr",
                    "start": 1950,
                    "end": 2000,
                    "hidetime": true
                },
            ]
        },
        // {
        //     "name": "Rangeln und Raufen für Kinder",
        //     "age": "ab 6 Jahren",
        //     "anker": "rangelnundraufen",
        //     "shortDescription": "Training für Körper und Geist",
        //     "description": 
        //         <div>
        //             Unser Programm wurde speziell für Kinder entwickelt und konzentriert sich auf die Entwicklung der körperlichen Fitness, Koordination, Disziplin und des Selbstbewusstseins. Wir bieten ein sicheres und spaßiges Umfeld, in dem Kinder lernen können, wie sie ihre Kraft und Energie auf eine positive Weise kanalisieren können.
        //             <br/><br/>
        //             "Rangeln und Raufen für Kids" ist eine Kampfsportart, die Techniken aus verschiedenen Kampfkünsten wie Ringen, Judo, Karate und Taekwondo vereint. In unserem Training lernen die Kinder grundlegende Kampftechniken, um sich selbst verteidigen zu können und um ihre Stärke und Ausdauer zu verbessern.
        //             <br/><br/>
        //             Unsere erfahrenen Trainer sind darauf spezialisiert, den Kindern beizubringen, wie sie ihre Körperbewegungen und -positionen verbessern können, um effektiver zu kämpfen und sich zu verteidigen. Wir fördern eine positive Einstellung und einen respektvollen Umgang miteinander, damit die Kinder nicht nur körperlich, sondern auch geistig und emotional wachsen können.
        //             <br/><br/>
        //             Unser Programm bietet auch die Möglichkeit, an Wettkämpfen teilzunehmen, aber unser Fokus liegt darauf, dass die Kinder lernen, ihre körperliche Fitness und ihre Technik zu verbessern, um ihre Ziele zu erreichen und ihr Potenzial auszuschöpfen. Wir glauben, dass jeder, der den Wunsch hat, lernen kann, wie man kämpft, und wir freuen uns darauf, Dein Kind in unserem Training begrüßen zu dürfen!
        //             <br/><br/>
        //             Wenn Du Interesse an unserem Kinder-Kampfsportprogramm "Rangeln und Raufen für Kids" hast, dann kontaktiere uns gerne und lass uns Dir mehr darüber erzählen. Wir sind stolz darauf, eine positive Umgebung zu schaffen, in der Kinder lernen können, sich selbst zu verteidigen und ihr Potenzial zu entfalten.
        //         </div>,
        //     "image": "rr.jpg",
        //     "style": {
        //         "backgroundColor": "rgba(255, 0, 255, 0.5)",
        //         "color": "rgba(0,0,0)"
        //     },
        //     "time": [
        //         {
        //             "day": "mi",
        //             "start": 1700,
        //             "end": 1850
        //         },
        //         // {
        //         //     "day": "fr",
        //         //     "start": 1500,
        //         //     "end": 1650
        //         // }
        //     ]
        // },
        {
            "name": "Open Mat",
            "kategorie": "Mixed",
            "age": "ab 14 Jahren",
            "anker": "openmat",
            "shortDescription": "Sparring, Rollen, Randori, Freikampf - Kostenlos für alle Kampfsportler*innen",
            "description": 
                <div>
                    Wir bieten allen Kampfsportlern die Möglichkeit, in unserer kostenlosen Open Mat Erfahrungen zu sammeln und sich gemeinsam mit anderen Kampfsportlern weiterzuentwickeln.
                    <br/><br/>
                    Unser Ziel bei der Open Mat ist es, eine Umgebung zu schaffen, in der jeder seine Kampftechniken verbessern und seine Fähigkeiten testen kann. Ob Du Anfänger oder Profi bist, jeder ist willkommen und wir ermutigen alle, zusammenzukommen und zu trainieren.
                    <br/><br/>
                    Während der Open Mat haben Kampfsportler die Möglichkeit, ihre Kampftechniken in allen Kampfsportarten wie Boxen, Muay Thai, Brazilian Jiu-Jitsu, Wrestling, Judo und vieles mehr zu trainieren. Unsere Open Mat ist der perfekte Ort, um mit anderen Kampfsportlern zu kämpfen und neue Techniken auszuprobieren.
                    <br/><br/>
                    Das gemeinsame Lernen und die Weiterentwicklung der eigenen Kampftechnik stehen im Vordergrund. Unsere erfahrenen Trainer sind während der Open Mat ebenfalls anwesend, um Ratschläge und Anweisungen zu geben und die Sicherheit zu gewährleisten.
                    <br/><br/>
                    Die Open Mat ist eine großartige Möglichkeit für Kampfsportler, sich zu vernetzen und neue Freunde zu finden, während sie ihre Fähigkeiten verbessern. Wir ermutigen alle Kampfsportler, egal welcher Erfahrungsstufe, teilzunehmen und sich selbst herauszufordern.
                    <br/><br/>
                    Komm vorbei und nimm an unserer Open Mat teil! Wir freuen uns darauf, Dich kennenzulernen und gemeinsam zu trainieren.
                </div>,
            "image": "om.jpg",
            "style": {
                "backgroundColor": "rgba(120, 120, 120, 0.5)",
                "color": "rgba(0,0,0)"
            },
            "time": [
                {
                    "day": "so",
                    "start": 1100,
                    "end": 1300
                }
            ]
        },
        // {
        //     "name": "Freies Training",
        //     "kategorie": "Mixed",
        //     "age": "ab 14 Jahren",
        //     "anker": "freetrain",
        //     "shortDescription": "Freies Training für Mitglieder",
        //     "description": 
        //         <div>
        //            Dies ist eine großartige Gelegenheit, um an euren Fähigkeiten zu arbeiten, euch zu verbessern und eure Ziele zu erreichen.
        //            <br/><br/>
        //             Das freie Training bietet euch die Möglichkeit, eure eigenen Trainingsziele zu setzen und in eurem eigenen Tempo zu arbeiten. Ob ihr euch auf Technik, Kraft, Ausdauer oder Flexibilität konzentrieren möchtet, das Training steht euch offen, um das Beste aus euch herauszuholen.
        //             <br/><br/>
        //             Unser Trainingsraum steht euch mit einer Vielzahl von Geräten und Trainingsutensilien zur Verfügung. Egal, ob ihr Gewichte heben, Cardio machen, Kampfsporttechniken üben oder einfach nur dehnen möchtet, ihr findet alles, was ihr braucht, um eure Ziele zu erreichen.
        //             <br/><br/>
        //             Bitte beachtet, dass während des freien Trainings die allgemeinen Regeln und Vorschriften gelten. Wir bitten euch daher, die Geräte und Einrichtungen sorgfältig zu verwenden und Rücksicht auf andere Mitglieder zu nehmen.
        //         </div>,
        //     "image": "ft.png",
        //     "style": {
        //         "backgroundColor": "rgba(120, 120, 120, 0.5)",
        //         "color": "rgba(0,0,0)"
        //     },
        //     "time": [
        //         {
        //             "day": "fr",
        //             "start": 1850,
        //             "end": 2000
        //         }
        //     ]
        // },
        {
            "name": "Wettkampf",
            "kategorie": "Mixed",
            "anker": "wettkampf",
            "shortDescription": "Teste dein Können!",
            "description": 
                <div>
                    Sparring und Wettkampf sind wichtige Mittel, um Selbstverteidigungstechniken zu erlernen und zu perfektionieren. Durch das Sparring kann man seine Techniken und Taktiken unter realistischen Bedingungen üben und seine Fähigkeiten in einer kontrollierten Umgebung testen. Es hilft einem, sich auf unvorhersehbare Situationen vorzubereiten und seine Reaktionszeit und Anpassungsfähigkeit zu verbessern.
                    <br/><br/>
                    Wettkämpfe sind ebenfalls ein wertvolles Mittel, um seine Fähigkeiten unter realen Bedingungen zu testen und sich auf verschiedene Kampfstile und -techniken einzustellen. Sie können dazu beitragen, dass man sich unter Druck auf seine Instinkte verlassen und schnell auf unerwartete Situationen reagieren kann.
                    <br/><br/>
                    Insgesamt sind Sparring und Wettkampf wertvolle Mittel, um Selbstverteidigungstechniken zu erlernen und zu perfektionieren. Man kann auf Wettkämpfe verzichten, solange man mit Wettkämpfern trainiert, sollte aber auf das Sparring nicht verzichten, um kontinuierlich seine Fähigkeiten zu verbessern und auf mögliche Selbstverteidigungssituationen vorbereitet zu sein.
                    <hr/>
                    <YouTube
                        videoId='58JtTr7Vcqk'
                        id='video'
                        // style={{outerWidth: "100"}}
                        opts={{
                            autoplay: 1,
                            controls: 0, 
                            rel : 0,
                            fs : 0,
                            loop: 1,
                            showinfo: 0,
                            mute: 1,
                            width: '100%'
                        }}
                    />
                </div>,
            "image": "podest.jpg",
            "style": {
                "backgroundColor": "rgba(120, 120, 120, 0.5)",
                "color": "rgba(0,0,0)"
            },
            "time": [

            ]
        },
    ],
    "kategories": [
        "Mixed", "Striking", "Grappling", "Meditativ"
    ],
    "days": [
        {
            "id": "mo",
            "name": "Montag"
        },
        {
            "id": "di",
            "name": "Dienstag"
        },
        {
            "id": "mi",
            "name": "Mittwoch"
        },
        {
            "id": "do",
            "name": "Donnerstag"
        },
        {
            "id": "fr",
            "name": "Freitag"
        },
        {
            "id": "sa",
            "name": "Samstag"
        },
        {
            "id": "so",
            "name": "Sonntag"
        }
    ]
}
